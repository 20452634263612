/* Global
-------------------------------------------------- */

img {
    -ms-interpolation-mode: bicubic;
}

body {
    /* fix viewport issues on iOS */
    overflow-x: hidden;
}

iframe {
    border: 0;
}

/* Special effects
-------------------------------------------------- */
.tnt-fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

.tnt-underlay {
    position: relative;
    top: 50%;
    width: 100%;
    z-index: 0;
    text-align: center;
    font-size: 24px;
}

/* Grid
-------------------------------------------------- */

.container {
    width: 100%;
}

@media (min-width: 768px) {
    .container.has-wallpaper,
    .has-wallpaper .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container.has-wallpaper,
    .has-wallpaper .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container.has-wallpaper,
    .has-wallpaper .container,
    .container {
        width: 1170px;
    }
}

/* fluid body container */
.container_fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

/* Defender grid */
/** IE11 needs auto to be in 1fr etc */
.tnt-grid,
.layout-grid-container.defender,
.layout-grid-sub {
    grid-column-gap: 30px;
    grid-row-gap: 0px;
}

@media screen and (min-width: 992px) {
    .layout-grid-container.defender {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 140px 1fr 300px;
        grid-template-columns: 140px minmax(0, auto) 300px;
    }
    
    .layout-grid-container.defender .colspan3 {
        -ms-grid-column-span: 3;
        grid-column-end: span 3;
    }
    
    /* col span end */
    .layout-grid-container.defender .colspan2 {
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
    }
    
    /* sub grid */
    .layout-grid-sub {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 300px 1fr 300px;
        grid-template-columns: 300px minmax(0, auto) 300px;
    }
    
}
@media screen and (min-width: 1200px) {
    .layout-grid-container.defender {
        -ms-grid-columns: 220px 1fr 300px;
            grid-template-columns: 220px minmax(0, auto) 300px;
    }
}

/* equal height columns and cards */
@media screen and (min-width: 768px) {

    .card-grid .row.equal,
    .card-grid .row.equal .recommendations-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        justify-content: flex-start;
    }

    .card-grid .row.equal > div[class*='col-'],
    .card-grid .row.equal .recommendations-container > div[class*='col-'] {
        display: -webkit-flex;
        display:flex;
        -ms-flex: 0 1 auto;
        margin-right:-1px;
    }

}

/* seamless grid */
.row.row-seamless {
  margin-right: 0;
  margin-left: 0;
}

.row.row-seamless > [class^="col-"],
.row.row-seamless > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* break out of parent container */
.container-breakout {
    margin:0 -100%; /* fallback */
    margin:0 calc(50% - 49.5vw);
}


/* Centerpiece grid */
@media (min-width: 992px) {
    .pull-right-lg {
        float: right;
    }

    .pull-left-lg {
        float: left;
    }

    .grid-centerpiece .fixed-col-left,
    .grid-centerpiece .fixed-col-right-nested,
    .grid-centerpiece .main-content.has-sidebar {
        width: calc(100% - 330px);
    }

    .grid-centerpiece .fixed-col-right,
    .grid-centerpiece .fixed-col-left-nested,
    .grid-centerpiece .main-sidebar {
        width: inherit;
        min-width: 330px;
        max-width: 330px;
    }

    .grid-centerpiece .row.equal-height {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

/* Add on to bootstrap visibility classes
-------------------------------------------------- */

.visible-xs-flex,
.visible-sm-flex,
.visible-md-flex,
.visible-lg-flex {
    display: none !important;
}
@media (max-width: 767px) {
    .visible-xs-flex {
        display: flex !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-flex {
        display: flex !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-flex {
        display: flex !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg-flex {
        display: flex !important;
    }
}

/* Custom button colors
-------------------------------------------------- */

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}
.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
    color: #fff;
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124;
}
.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus {
    background-color: #343a40;
    border-color: #343a40;
}
.btn-dark .badge {
    color: #343a40;
    background-color: #fff;
}


/* Navbar
-------------------------------------------------- */

/* remove navbar toggle button outline */
.navbar-toggle {
    outline: none;
}

.navbar-toggle.pull-left {
    margin-left: 15px;
    margin-right: 0;
}

.navbar-toggle .sr-only-show {
    float: left;
    margin-left: 34px;
    font-size: 11px;
    text-transform: uppercase;
}

@media screen and (min-width: 992px) {

    .navbar-toggle.hamburger-desktop {
        margin: 0!important;
        border-radius: 0;
        padding: 9px 15px 4px 15px;
        opacity: .9;
    }

    .navbar-toggle.hamburger-desktop:hover {
        opacity: 1;
    }

    .navbar-toggle.hamburger-desktop .sr-only-show {
        float: left;
        margin-left: 0;
        margin-top: 4px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .navbar-toggle.hamburger-desktop .icon-bar {
        width: 32px;
        height: 3px;
        border-radius: 3px;
    }

}

.navbar-brand img {
    max-height: 30px;
    width: auto;
    height: auto;
}

.site-search-full .dropdown-menu {
    border-color: #e0e0e0;
}

.site-search-full-mobile {
    margin: 0;
}

.site-search-full-mobile .dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
}

.site-search-full-mobile .open .dropdown-menu {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.site-search-full-mobile .form-control {
    border: none;
    box-shadow: none;
}

.site-search-form {
    transition: width 0.5s;
}

@media screen and (max-width: 991px) {

    .container > .navbar-header,
    .container > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }

    .navbar-brand.pos_left_right,
    .navbar-brand.pos_left_right div {
        text-align: center !important;
        float: none;
    }

}

/* Navs
-------------------------------------------------- */

/* add pointer to items in menu that are not actual links */
#site-top-nav-container li a,
#main-navigation li a {
    cursor: pointer;
}

/* Top Nav
-------------------------------------------------- */

#site-top-nav .list-inline.nav-list {
    margin-left: 0;
}

#site-top-nav .list-inline.nav-list > li {
    padding: 0;
    margin-right: 12px;
}

/* Main Nav
-------------------------------------------------- */

body.fixed-nav #site-container {
    margin-top: 50px;
}

#site-navbar-container.affix {
    top: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 500;
}

#site-navbar-container.affix-sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    z-index: 500;
    top: 0;
}

/* Main nav buttons */
.navbar-default .navbar-nav>li>button.nav-link {
    font-weight: bold;
}

.navbar-nav > li > button.nav-link {
    border: none;
    padding: 15px;
    line-height: 20px;
}

.navbar-nav > li > button.nav-link:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0!important;
}

.navbar-nav > .open > button.nav-link,
.navbar-nav > .open > button.nav-link:hover,
.navbar-nav > .open > button.nav-link:focus {
    background-color: #fff!important;
    color: #333!important;
}

/* dynamic navigation style */
#main-navigation.navbar-dynamic .navbar-header {
    height: 50px;
}

#main-navigation.navbar-dynamic .navbar-left {
    overflow: hidden;
    max-width: 75%;
    white-space: nowrap;
}

#main-navigation.navbar-dynamic #main-nav_menu > li {
    display: inline-block;
    float: none;
}

#main-navigation.navbar-dynamic .navbar-brand {
    display: block !important;
    height: auto;
    overflow: hidden;
    max-width: inherit;
    padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: max-width .5s ease-in-out;
}

#main-navigation.navbar-dynamic #navbar-asset-header {
    border-left: 1px solid rgba(0,0,0,0.1);
    position: absolute;
    display: none;
    opacity: 0;
    top: 0;
    right: 0;
    background-color: #fff;
    z-index: 101;
}

#main-navigation.navbar-dynamic #navbar-asset-header.sticky {
    display: block;
    opacity: 1;
}

#main-navigation.navbar-dynamic #navbar-asset-header .title {
    line-height: 50px;
    padding-left: 15px;
    font-weight: bold;
    overflow: hidden;
}

#main-navigation.navbar-dynamic #navbar-asset-header .share-header {
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, white 10%);
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 15px 0 50px
}

#main-navigation.navbar-dynamic #navbar-asset-header .share-label {
    line-height: 50px;
    opacity: .6;
    text-transform: uppercase;
    margin-right: 10px;
}

#main-navigation.navbar-dynamic #navbar-asset-header .share-container {
    display: inline-block;
}

#main-navigation.navbar-dynamic #navbar-asset-header .share-container .social-share-links {
    margin: 10px 0 0;
}

#main-navigation.navbar-dynamic #navbar-asset-header .share-container .tnt-svg {
    font-size: 14px;
}

@media screen and (min-width: 768px) {

    #main-navigation #navbar-collapse {
        padding-right: 0;
        padding-left: 0;
    }

    #main-navigation .navbar-nav.navbar-right {
        margin-right: 0;
    }

    #main-navigation .dropdown-menu li:hover > .dropdown-menu {
        display: block;
    }

    #main-navigation .dropdown-menu li {
        position: relative;
    }

    #main-navigation .dropdown-menu li .dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
        margin-top: -5px;
        margin-left: -1px;
        border: 0;
    }

    #main-navigation .dropdown-menu li .tnt-caret-right {
        margin-right: -5px;
        color: rgba(0,0,0,.1);
        float: right;
    }

}

@media screen and (max-width: 768px) {

    #main-navigation .dropdown-menu li .tnt-caret-right {
        display: none;
    }

    #main-navigation .dropdown-menu li.open a {
        background: transparent;
    }

    #main-navigation .dropdown-menu li .dropdown-menu {
        display: block!important;
        margin-left: 10px;
    }

    #main-navigation.navbar-dynamic #navbar-asset-header .share-header {
        width: 100vw;
        background: #fff;
    }

    #main-navigation.navbar-dynamic .share-container .list-inline.social-share-links a,
    #main-navigation.navbar-dynamic .share-container .list-inline.social-share-links button {
        padding: 0;
    }

}

@media screen and (min-width: 992px) {

    #main-navigation .navbar-collapse .pull-right .tncms-block {
        float: right;
    }

    #main-navigation.navbar-dynamic .navbar-brand {
        max-width: 0px;
    }

    #site-navbar-container.affix #main-navigation.navbar-dynamic .navbar-brand,
    .blox-asset #site-navbar-container #main-navigation.navbar-dynamic .navbar-brand {
        max-width: 300px;
    }

}

@media screen and (max-width: 991px) {

    #main-navigation {
        width: auto;
        border-right: 0;
        border-left: 0;
        margin-bottom: 0;
    }

    #main-navigation #navbar-collapse {
        padding-right: 15px;
        padding-left: 15px;
        max-height: 340px;
    }

    #main-navigation #navbar-collapse .pull-right {
        float: none !important;
    }

}


/* Off Canvas
-------------------------------------------------- */

/* prevent body scroll when drawer opened. This doesnt work well on iOS. */
.drawer-open {
    overflow: hidden !important;
}

/* canvas inner content */
.offcanvas-inner {
    position: relative;
    left: 0;
    -webkit-transition: left 0.25s ease-out;
    -moz-transition: left 0.25s ease-out;
    -o-transition: left 0.25s ease-out;
    -ms-transition: left 0.25s ease-out;
    transition: left 0.25s ease-out;
}

.offcanvas-drawer-left .tncms-region,
.offcanvas-drawer-right .tncms-region {
}

/* left drawer closed */
.offcanvas-drawer-left,
.offcanvas-content-left {
    left: -80%;
}

/* left drawer open */
.active-left .offcanvas-drawer-left,
.active-left .offcanvas-content-left {
    left: 0;
}

/* left drawer open content */
.active-left .offcanvas-inner,
/* move fixed nav */
.active-left .navbar-fixed-top,
.active-left #site-navbar-container.affix {
    left: 80%;
}

/* right drawer closed */
.offcanvas-drawer-right,
.offcanvas-content-right {
    right: -80%;
}

/* left drawer open */
.active-right .offcanvas-drawer-right,
.active-right .offcanvas-content-right {
    right: 0;
}

/* left drawer open content */
.active-right .offcanvas-inner,
.active-right .navbar-fixed-top,
.active-right #site-navbar-container.affix {
    left: -80%;
}

/* make sure fixed nav does not collapse */
.drawer-open .navbar-fixed-top,
.drawer-open #site-navbar-container.affix {
    width: 100% !important;
    -webkit-transition: left 0.25s ease-out;
    -moz-transition: left 0.25s ease-out;
    -o-transition: left 0.25s ease-out;
    -ms-transition: left 0.25s ease-out;
    transition: left 0.25s ease-out;
}

/* affix left */
#site-navbar-container.affix {
    left: 0;
}

/* fixed nav toggle positions */
.navbar-fixed-top .navbar-toggle.pull-left {
    margin-left: 0;
}

.navbar-fixed-top .navbar-toggle.pull-right {
    margin-right: 0;
}

/* drawer base styles */
.offcanvas-drawer,
.offcanvas-content-left,
.offcanvas-content-right {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 80%;
    z-index: 1050;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

/* close button */
.offcanvas-close-btn {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1031;
    height: 100%;
    cursor: pointer;
    display: none;
    opacity: 0;
    width: 100%;
}

.drawer-open .offcanvas-close-btn {
    display: block;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    background-color: rgba(0, 0, 0, .5);
}

.offcanvas-drawer .hide-offcanvas {
    display: none!important;
}

.offcanvas-drawer .show-offcanvas {
    display: block!important;
}

/* Off Canvas Theme */
.drawer-open .navbar-toggle .icon-bar {
    opacity: .5;
}

.offcanvas-drawer,
.offcanvas-content-left,
.offcanvas-content-right {
    background-color: #333;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2)
}

.offcanvas-drawer-left {
    -webkit-box-shadow: inset -8px 0 5px 0 rgba(0, 0, 0, .2);
    box-shadow: inset -8px 0 5px 0 rgba(0, 0, 0, .2);
}

.offcanvas-drawer-right {
    -webkit-box-shadow: inset 8px 0 5px 0 rgba(0, 0, 0, .2);
    box-shadow: inset 8px 0 5px 0 rgba(0, 0, 0, .2);
}

.offcanvas-drawer .panel {
    border-radius: 0!important;
    border: 0;
    box-shadow: none;
    margin: 0!important;
    background-color: transparent;
}

.offcanvas-drawer .navbar-nav .panel-heading {
    padding: 0!important;
    background-color: transparent!important;
    border-radius: 0!important;
}

.offcanvas-drawer .navbar-nav,
.offcanvas-drawer .navbar-nav li {
    margin: 0;
    float: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5) !important;
}

.offcanvas-drawer .navbar-nav li,
.offcanvas-drawer .navbar-nav .panel-title {
    border-top: 1px solid rgba(255, 255, 255, .075) !important;
    border-bottom: 1px solid rgba(0, 0, 0, .2) !important;
}

.offcanvas-drawer a,
.offcanvas-drawer a:hover {
    color: rgba(255, 255, 255, 0.6);
}

.offcanvas-drawer .navbar-nav li a,
.offcanvas-drawer .navbar-nav .panel-title a,
.offcanvas-drawer .navbar-nav .panel-title .expand-label,
.offcanvas-drawer .open .dropdown-menu > li > a,
.offcanvas-drawer .navbar-nav .panel-title .expand-children {
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    font-weight: bold;
    padding: 15px;
    line-height: 16px;
    display: block;
    background-color: transparent;
    text-decoration: none;
    border: 0 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5) !important;
}

.offcanvas-drawer .navbar-nav li a {
    padding-left: 30px;
    font-weight: normal;
}

.offcanvas-drawer .open .dropdown-menu > li > a {
    padding-left: 25px;
}

.offcanvas-drawer .dropdown ul,
.offcanvas-drawer .panel ul {
    padding: 0;
    background-color: rgba(0, 0, 0, .2);
}

.offcanvas-drawer .navbar-nav .panel-title:hover,
.offcanvas-drawer .navbar-nav li a:hover,
.offcanvas-drawer .navbar-nav li.open,
.offcanvas-drawer .navbar-nav li:active,
.offcanvas-drawer .navbar-nav li.open > a {
    background-color: rgba(0, 0, 0, .2);
}

.offcanvas-drawer .navbar-nav h3 {
    margin: 0;
    background-color: #333;
    color: #888;
    border-top: 1px solid rgba(255, 255, 255, .1);
    font-size: 14px;
    text-transform: uppercase;
    padding: 6px 15px;
}

.offcanvas-drawer .navbar-nav span.expand-children.collapsed {
    color: rgba(255, 255, 255, 0.5);
    transform: none;
    -webkit-transform: none;
}

.offcanvas-drawer .navbar-nav span.expand-children:hover {
    color: rgba(255, 255, 255, 0.7)
}

.offcanvas-drawer .navbar-nav span.expand-children {
    color: rgba(255, 255, 255, 0.85);
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    cursor: pointer;
}

/* user controls & search block */
.user-controls .tn-user-avatar img {
    height: 18px;
}

.user-controls .large-user-avatar .tn-user-avatar img {
    height: 30px;
}

.user-controls .large-user-avatar .tn-user-avatar .user-icon {
    border-radius: 50%;
}

.offcanvas-drawer .user-controls .large-user-avatar img {
    height: 40px;
}

.offcanvas-drawer .user-controls .large-user-controls {
    font-size: 14px!important;
}

.offcanvas-drawer .user-controls .large-user-controls .user-welcome {
    max-width: 175px!important;
}

.offcanvas-drawer .user-controls,
.offcanvas-drawer .tnt-photo-sales-cart {
    background-color: rgba(0, 0, 0, .2);
}

.offcanvas-drawer .user-controls .list-inline {
    margin-bottom: 0;
}

.offcanvas-drawer .search-form-container,
.offcanvas-drawer .user-controls,
.offcanvas-drawer .tnt-photo-sales-cart {
    padding: 15px;
    width: auto;
    border-top: 1px solid rgba(255, 255, 255, .075) !important;
    border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
}

.offcanvas-drawer .search-form-container {
    background-color: rgba(0, 0, 0, .5);
}

.offcanvas-drawer .tnt-photo-sales-cart {
    font-size: 17px;
    margin-bottom: 0;
}

.yamm .user-controls .dropdown img {
    max-width: 100%;
}

/* Photo sales buy now button */
.tnt-photo-purchase {
    position: relative;
    margin-bottom: 10px;
}

.tnt-photo-purchase .tnt-spinner {
    position: absolute;
    top: 20%;
    left: 40%;
}

/* photo sales cart block */
.tnt-photo-sales-cart a.open-cart {
    position: relative;
}

.tnt-photo-sales-cart.cart-icon a:hover {
    text-decoration: none;
}

/* Fotomoto cart */
.FotomotoViewCartClass {
    /* prevents Fotomoto cart from overlapping modal close button */
    top: 50px !important; 
}

/* desktop offcanvas overwrites */
@media screen and (min-width: 991px) {

    .navbar-toggle.hamburger-desktop {
        display: block;
    }

    .navbar-toggle.hamburger-desktop.fluid-header {
        margin-left: 0;
    }

    .drawer-open {
        overflow: auto!important;
    }

    .offcanvas-drawer,
    .offcanvas-content-left {
        width: 300px;
    }

    .drawer-open .offcanvas-inner,
    .drawer-open .navbar-fixed-top,
    .drawer-open #site-navbar-container.affix {
        left: 0px;
    }

    .offcanvas-drawer-left {
        box-shadow: none;
        border-right: 1px solid rgba(0, 0, 0, .2);
        -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
    }

    .offcanvas-drawer .navbar-nav li,
    .offcanvas-drawer .navbar-nav .panel-title {
        border-top: 0!important;
    }

    .offcanvas-content-left {
        text-shadow: none;
    }

    .offcanvas-drawer .navbar-nav li a,
    .offcanvas-drawer .navbar-nav .panel-title a,
    .offcanvas-drawer .navbar-nav .panel-title .expand-label,
    .offcanvas-drawer .navbar-nav .panel-title .expand-children,
    .offcanvas-drawer .open .dropdown-menu > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
        text-shadow: none;
    }

    .offcanvas-drawer .block-title {
        margin: 0;
        padding: 10px 15px;
    }

}


/* Badge dot
-------------------------------------------------- */

.badge-dot {
    width: 10px;
    height: 10px;
    background-color: #777;
    display: block;
    position: absolute;
    right: 10px;
    top: 12px;
    border-radius: 50%;
    z-index: 1;
}


/* Utility Classes
-------------------------------------------------- */

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide headlines and details */
.tnt-hide-headlines .card-headline,
.tnt-hide-details .caption-text,
.tnt-hide-details .card-meta {
    display: none;
}

/**
 * Vertically center any content
 */
.centered-content-stage {
    position: relative;
}

.centered-content-container { /* any height */ }
.centered-content-container:before {
    /* The ghost, nudged to maintain mostly perfect centering. */
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
}

.centered-content {
    /* The element to be centered, can be any width and height */
    display: inline-block;
    vertical-align: middle;
}

.content-overlay {
    /* Add this class to the container to overlay content */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* responsive video containers */
.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-responsive[data-provider='field59'] {
    overflow: visible;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* video overlays */
.video-overlay-play-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 64px;
    cursor: pointer;
}

.video-overlay-play-button:hover {
    text-decoration: none;
}

.tnt-progress-container {
    position:relative;
    height: 10px;
    background-color: #ccc;
}
.tnt-progress-meter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.4);
}

/* Field59 adjustments */
.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-0 p {
    color: #000000;
}

.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-1 p {
    color: #0000ff;
}

.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-2 p {
    color: #008000;
}

.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-3 p {
    color: #00ffff;
}

.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-4 p {
    color: #ff0000;
}

.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-5 p {
    color: #ff00ff;
}

.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-6 p {
    color: #ffff00;
}

.asset #asset-content .f59-main .fp-subtitle.fp-f59-cc-color-7 p {
    color: #ffffff;
}

/* Bitcentral adjustments */
.tnt-video-container[data-provider="bitcentral"] .is-sticky, 
.tnt-video-container[data-provider="bitcentral"] .videoTag .is-sticky {
    top: auto;
    bottom: 100px;
}

.tnt-video-container[data-provider="bitcentral"] .close-button {
    top: auto;
    bottom: 270px;
}

.tnt-video-container[data-provider="bitcentral"] svg[data-icon="null"] {
    display: none;
}

/* Content stream with vertical bullets and connecting line */
.stream-container {
    position: relative;
    margin-bottom: 20px;
}

.stream-container::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 17px;
    height: 100%;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(0,0,0,.1);
}

.stream-item {
    position: relative
}

.stream-item-bullet {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
}

.stream-item-content {
    position: relative;
}

.stream-container .date-partition {
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 56px 0;
    font-style: italic;
    color: #535353;
    clear: left;
}

.stream-item-bullet-date {
    color: #a1a1a1;
    text-align: center;
    margin-top: 4px;
    font-size: 14px;
}

.stream-container.stream-sm::before{
    left: 9px;
    border-width: 1px;
}

.stream-container.stream-sm .stream-item-bullet{
    width: 20px;
    height: 20px;
}

.show-onload {
    display: none;
}

.not-logged-in,
.logged-in {
    display: none;
}

.margin-bottom-none,
#main-body-container .margin-bottom-none .block {
    margin-bottom: 0px;
}

.invisible {
    visibility: hidden;
}

.padding-top {
    padding-top: 15px;
}

.padding-right {
    padding-right: 15px;
}

.padding-bottom {
    padding-bottom: 15px;
}

.tnt-no-margin-bottom,
.block.tnt-no-margin-bottom {
    margin-bottom: 0px!important;
}

.tnt-has-block-bg {
    padding: 20px;
}

.padding-left {
    padding-left: 15px;
}

.border-right {
    border-right: 1px solid rgba(0,0,0,0.1);
}

.border-left {
    border-left: 1px solid rgba(0,0,0,0.1);
}

.border-top {
    border-top: 1px solid rgba(0,0,0,0.1);
}

.border-bottom {
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.text-left {
    text-align: left!important;
}
.text-right {
    text-align: right!important;
}
.text-center {
    text-align: center!important;
}
.underline {
    text-decoration: underline;
}

/* Syndicated ads */
.asset-syndicated-ads {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {

    .asset-syndicated-ads {
        border: 1px solid rgba(0,0,0,.1);
        padding: 15px;
        background: #fff;
    }

    .asset-syndicated-ads .tnt-ads {
        margin-bottom: 10px;
    }

}


/* Pagination
-------------------------------------------------- */

.pagination-controls {
    display: inline-block;
    width: 100%;
    text-align: center;
}
.pagination-controls a {
    cursor: pointer;
}

.pagination-controls a.disabled {
    cursor: not-allowed;
}

@media print{    
    .asset-content.carousel .item {
        display: block !important; 
    }
}


/* Infinity scroll
-------------------------------------------------- */

/* Summary */
#infinite-container.infinity-summary-container {
    padding-bottom: 70px;
}

#infinite-container.infinity-summary-container .infinite-item {
    margin-top: 20px;
}

#infinite-container .card-compact > .card,
#infinite-container .card-compact > .card:first-child {
    border: 0px;
    border-top: 0px;
}

#infinite-more {
    font-size: 50px;
    text-align: center;
}

.infinity-ad-side-primary {
    text-align: center;
}

#infinite-container .infinity-ad iframe,
.infinity-ad-side-primary iframe {
    border: 0;
    width: 315px;
    height: 266px;
    overflow: hidden;
}

.automatic-ad {
    width: 100%;
}

.automatic-ad iframe {
    background-color: transparent;
    border: 0;
    width: 300px;
    height: 270px;
    overflow: hidden;
    margin: 0px auto 20px;
    display: block;
    clear: both;
}

#infinite-container.infinity-summary-container .card-lead {
    font-size: larger;
}

.tnt-infinity-loader {
    text-align: center;
    font-size:50px;
}

.tnt-load-more {
    margin: 0 auto 20px; 
}

.block.dark a.tnt-load-more,
.block.dark a:hover.tnt-load-more {
    color: #fff;
}

.tnt-tribute {
    background-color: rgba(0,0,0,.1);
}

.tnt-send-tribute {
    background-color: #ff5a5f;
}

@media screen and (min-width: 480px) {
    .tnt-load-more {
        max-width: 300px;
    }
}

/* Infinity Story Scroll
-------------------------------------------------- */

#infinite-nav-container {
    max-height: 360px;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    margin-bottom: 40px;
    border: 1px solid #ddd;
    border-left: none;
}

#infinite-nav-container .list-group {
    margin-bottom: 0;
}

#infinite-nav-container .list-group-item {
    cursor: pointer;
}

#infinite-nav-container .list-group-item:first-child {
    border-top: none;
}

#infinite-nav-container .list-group-item:last-child {
    border-bottom: none;
}

#btn-infinite-load {
    display: block;
    margin: 0 auto 40px auto;
}

.infinity-story .infinity-ad {
    text-align: center;
}

.asset.infinity-story article.tncms-inline-asset blockquote {
    padding: 15px;
    background: #eee;
    border: 1px solid #ddd;
}

.asset.infinity-story article.tncms-inline-asset blockquote p {
    color: #333;
    font-size: 14px;
    line-height: 1.68;
}

.asset.infinity-story figure.tncms-inline-asset img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto 5px;
}

.asset.infinity-story figure.tncms-inline-asset figcaption {
    margin: 0 auto 24px;
    width: 400px;
    max-width: 100%;
    font-size: 13px;
    color: #666;
    line-height: 1.4em;
}

.asset.infinity-story figure.tncms-inline-asset figcaption p {
    font-size: 13px;
    color: #666;
    line-height: 1.4em;
}

.asset.infinity-story video.tncms-inline-asset {
    width: 100%;
    height: auto;
}

.asset.infinity-story iframe.tncms-inline-asset {
    display: block;
    margin: 0 auto 24px auto;
}

.asset.infinity-story table.tncms-inline-asset,
.asset.infinity-story div.tncms-inline-asset,
.asset.infinity-story video.tncms-inline-asset {
    margin-bottom: 24px;
}

/* inline table styles */
.asset.infinity-story table.tncms-inline-asset tr > td,
.asset.infinity-story table.tncms-inline-asset tr > th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.asset.infinity-story table.tncms-inline-asset > thead:first-child > tr:first-child > td,
.asset.infinity-story table.tncms-inline-asset > thead:first-child > tr:first-child > th {
    border-top: 0;
}

.asset.infinity-story table.tncms-inline-asset > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}


/* Asset Tributes
-------------------------------------------------- */

.asset-tribute-events {
    font-size: 16px;
}

.asset-tribute-events .event-date-square {
    float: left;
    width: 55px;
    text-align: center;
    padding: 0 10px;
}

.asset-tribute-events .event-date-square .event-day {
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
}

.asset-tribute-events .event-date-square .event-month {
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
}

.asset-tribute-events .event-type {
    line-height: 35px;
}

.asset-tribute-events .event-type,
.asset-tribute-events .event-time,
.asset-tribute-events .event-address {
    padding-left: 55px;
}

.asset-tribute-event,
.asset-tribute-events h3,
.asset-tribute-events .event-date-type,
.asset-tribute-events .event-date-time,
.asset-tribute-events .event-location {
    margin-bottom: 20px;
}

.asset-tribute-events .tribute-store-guarantee {
    text-align: center;
    padding: 0 40px;
}

.tribute-store-btn {
    padding: 0 20px;
    margin-bottom: 10px;
}

.tribute-store-btn .btn {
    background-color: #ff5a5f;
    white-space: normal;
}

.asset-tribute-events .tnt-svg {
    float: left;
    margin-top: 5px;
    width: 55px;
}

.tnt-load-more {
    margin: 0 auto 20px; 
}

.tnt-tribute {
    border-top: 1px solid rgba(0,0,0,.1);
    text-align: center;
}

.tnt-tribute-link {
    color: #ff5a5f;
}


/* Sticky Regions
-------------------------------------------------- */

#tncms-region-article_aside_sticky {
    position: static;
    margin-bottom: 40px;
}

#sticky-right-rail {
    position: static;
    overflow-y: auto;
}

.sticky-parent {
    position: relative;
}


/* Carousel
-------------------------------------------------- */

.owl-carousel .owl-stage {
    max-width: none;
}

/* carousel icons */
.carousel-control .control-left,
.carousel-control .control-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}

.carousel-control .control-left {
    left: 50%;
}

.carousel-control .control-right {
    right: 50%;
}


/* Photo Carousel
-------------------------------------------------- */

.photo-carousel .photo-ad {
    min-height: 290px;
    padding-top: 20px;
}

.photo-carousel .photo-ad.has-overline {
    min-height: 310px;
    padding-top: 20px;
}

.photo-carousel .owl-controls {
    display: none;
}

@media screen and (max-width: 768px) {

    .photo-carousel .photo-ad {
        min-height: 250px;
        padding-top: 0;
    }

}

.photo-carousel .photo-carousel-control {
    color: rgba(0,0,0,.4);
    cursor: pointer;
    z-index: 2;
    position: absolute;
    background-color: rgba(255,255,255,.9);
    padding: 5px 10px;
    font-size: 18px;
    user-select: none;
    opacity: 0.6;
}

.photo-carousel .photo-carousel-control:hover {
    color: rgba(0,0,0,.8);
    background-color: #fff;
}

.photo-carousel:hover .photo-carousel-control {
    opacity: 1;
}

.photo-carousel .photo-carousel-control.left {
    left: auto;
    right: 40px;
    top: 5px;
}

.photo-carousel .photo-carousel-control.right {
    right: 5px;
    top: 5px;
}

.photo-carousel .item {
    text-align: center;
}

.photo-carousel .item .photo-container {
    margin: 0 auto;
    display: block;
    position: relative;
}

.photo-carousel .item .photo-container .tnt-play-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255,255,255,1) !important;
    filter: drop-shadow(2px 0px 4px rgba(0,0,0,1));
    opacity: 0.6;
    transition: opacity 1s;
    z-index: 9;
}

.photo-carousel .item .photo-container .tnt-play-circle:hover {
    opacity: 0.8;
}

.photo-carousel .item .photo-container a{
    display: block;
}

.photo-carousel .item .photo-container a.limit-slide {
    color: #fff;
    height: 250px;
    display: flex;
    align-items: center;
    text-align: center;
}

.photo-carousel .item .photo-container a.limit-slide .limit-notice {
    width:100%;
}

.photo-carousel .item .photo-container,
.asset-photo .image {
    background-color: rgba(0,0,0,.85);
}

.photo-carousel .item .photo-container img {
    display: initial;
    object-fit: contain;
    max-height: 500px;
    max-width:100%;
    width: auto;
    margin: 0 auto;
}

.photo-carousel.single-photo .item .photo-container img {
    max-width: 100%;
    width: auto;
    max-height: initial;
}

.photo-carousel.single-photo .item .photo-container.layout-vertical img,
.photo-carousel .item .photo-container.layout-vertical img,
.photo-carousel .item .photo-container.layout-square img,
#asset-photo-carousel .layout-vertical img,
.asset-photo .layout-vertical img,
.asset-photo .layout-square img {
    width:auto;
    max-height:500px;
    object-fit: contain;
}

@media only screen and (max-width: 375px){
    .asset-photo .layout-square img, 
    .asset-photo .layout-vertical img, 
    .photo-carousel .item .photo-container.layout-square img, 
    .photo-carousel .item .photo-container.layout-vertical img, 
    .photo-carousel.single-photo .item .photo-container.layout-vertical img {
        height: auto;
        width: 100%;
    }   
}

.asset-photo img, 
#asset-content .asset-body .twitter-tweet {
    margin: 0 auto;
}

.photo-carousel .photo-count {
    z-index: 1;
    position: absolute;
    font-weight: bold;
    padding: 15px 0px 0px 0px;
}

.photo-carousel.paging .photo-count {
    top: -3px;
    right: 75px;
}
.photo-carousel .photo-count,.photo-carousel.default-collection .photo-count {
    right: 0px;
    top: -35px;
}

.slideshow-carousel-container .photo-carousel .card-headline * {
    margin-top:0;
}

.photo-carousel {
    z-index: 1;
    position: relative;
    height: 100%;
    width: 100%;
}

.photo-carousel .caption-container {
    text-align: left;
}

.photo-carousel [data-display="modal"] .caption-content {
    max-height: 50vh;
    overflow-y: auto;
}

.photo-carousel [data-display=modal] .caption-content.collapsing {
    overflow-y: hidden;
}

.caption-container p:last-child,
.asset .caption-text p:last-child {
    margin-bottom: 0px;
}

.hover-expand {
    cursor: pointer;
    position: relative;
}

.hover-expand figcaption {
    cursor: initial;
}

.hover-expand .expand {
    transition: all 0.25s ease-out;
    opacity: 0;
    font-size: 18px!important;
    z-index: 99;
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(0,0,0,.5);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

.hover-expand:hover .expand {
    opacity: 1;
}

.photo-carousel .stock-photo-label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    transition: all 0.25s ease-out;
}

.photo-carousel .loading-slide {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 30px;
    top: 0;
    bottom: 0;
    color: #fff;
    padding: 0;
    font-size: 18px;
}

.photo-carousel .outbrain-slide .loading-slide {
    display: none;
}

.pdf-content-alt .hover-expand .expand,
.tnt-file .hover-expand .expand {
    display: inline-block;
    position: static;
    opacity: 1;
    margin-bottom: 15px;
}

.photo-carousel .card-contact {
    margin-bottom: 10px;
}

.photo-carousel .card-contact .tnt-svg,
.photo-carousel .caption-social {
    opacity: .5;
}

.photo-carousel .card-contact .card-isopen {
    font-size: 12px;
    color: #51B201;
}

.photo-carousel .event .caption-date,
.photo-carousel .event .caption-venue,
.photo-carousel .event .caption-cost,
.photo-carousel .event .caption-website {
    font-size: 16px;
    margin-bottom: 8px;
}

.photo-carousel .event .caption-text {
    margin: 20px 0;
}

.photo-carousel .event .caption-details-venue .block-title h4 {
    color: rgb(0,0,0);
}

.photo-carousel .event .caption-details-venue .block-title-inner {
    border-bottom: 2px solid rgba(51,51,51,.5);
}

.photo-carousel .event .card.business {
    border: 1px solid rgba(0,0,0,.08);
}

.photo-carousel .event .card.business .card-body {
    padding: 15px;
}

.photo-carousel .outbrain-slide,
.photo-carousel .outbrain-slide img {
    width: 100%;
} 

@media screen and (max-width: 991px) {

    .photo-carousel .photo-carousel-control {
        opacity: 1;
    }

    .modal.fullscreen .photo-carousel .item .caption-container,
    .modal.fullscreen .photo-carousel .item.hover .caption-container {
        opacity: 1!important;
    }

    .modal.fullscreen .photo-carousel .photo-count {
        top: 5px;
        left: 5px;
        right: auto;
        bottom: auto;
    }

    .modal.fullscreen .photo-carousel .item .photo-container {
        padding: 0;
    }

    .modal.fullscreen .photo-carousel .photo-carousel-control {
        display: none;
    }

    .modal.fullscreen .photo-carousel-control-container {
        opacity: 1;
        position: absolute;
        background-color: rgba(0,0,0,.7);
        width: 100%;
        height: 50px;
        z-index: 5;
    }

    .modal.fullscreen.hover .photo-carousel .photo-carousel-control-container {
        opacity: 1;
    }

    .modal.fullscreen .close {
        top: 5px;
        right: 10px;
        opacity: 1;
    }

    .modal.fullscreen.hover .close {
        opacity: .5;
    }

    .hover-expand .expand {
        opacity: 1;
    }

}


/* Paging Carousel
-------------------------------------------------- */

.collection-gallery-paging .caption-container-paging {
    text-align: left;
    padding-top: 20px;
}

.collection-gallery-paging .photo-carousel-control-container-paging {
    text-align: right;
    margin-bottom: 20px;
}

.collection-gallery-paging .photo-carousel-control-container-paging.bottom {
    padding: 10px 0px;
}

.collection-gallery-paging .photo-carousel-control.left,
.collection-gallery-paging .photo-carousel-control.right {
    left: auto;
    right: auto;
    top: auto;
}

.collection-gallery-paging .photo-carousel-control {
    color: rgba(0,0,0,.4);
    opacity: 1;
    position: relative;
    padding: 5px 10px;
    border: 1px solid rgba(0,0,0,.2);
}

.collection-gallery-paging .photo-carousel-control:hover {
    background-color: rgba(0,0,0,.05);
}

.collection-gallery-paging .paging-position {
    padding-top: 17px;
}


/* Vertical Gallery
-------------------------------------------------- */

.gallery-vertical .gallery-item {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.gallery-vertical .gallery-item .item-ad {
    border-top: 1px solid rgba(0,0,0,.05);
    padding-top: 40px;
    margin-bottom: -40px;
    min-height: 280px;
}


/* Engagement module
-------------------------------------------------- */

/* Engagment with feature cards */
.engagement-container .engagement-item,
.engagement-container[data-engagement-total="1"] .engagement-item,
.engagement-container[data-engagement-total="2"] .engagement-item,
.engagement-container[data-engagement-total="3"] .engagement-item,
.engagement-container[data-engagement-total="4"] .engagement-item,
.engagement-container[data-engagement-total="5"] .engagement-item {
    width: 50%;
    float: left;
    display: none;
}

.engagement-container .engagement-item.item-1,
.engagement-container .engagement-item.item-2 {
    display: block; /* show only 2 on small devices */
}

.engagement-container[data-engagement-total="4"] .engagement-item.item-3,
.engagement-container[data-engagement-total="5"] .engagement-item.item-3,
.engagement-item.item-4,
.engagement-item.item-7 {
    clear: left;
}

.engagement-container[data-engagement-total="4"] .engagement-item.item-4,
.engagement-container[data-engagement-total="5"] .engagement-item.item-4 {
    clear: none;
}

.engagement-container .engagement-item .card-headline {
    max-height: 60px;
    overflow: hidden;
}

/* modal Engagement */
.modal.fullscreen .photo-carousel .engagement-container {
    position: relative;
    top: 25%;
}

.modal.fullscreen .photo-carousel .item .photo-container .engagement-container img {
    -webkit-transform: none!important;
    transform: none!important;
}

.engagement-container .block,
.engagement-container figure .image {
    margin-bottom: 0px!important;
}

.engagement-asset .card {
    margin-bottom: 0px;
}

.engagement-asset .card a.centered-content-link {
    z-index: 3;
}

.engagement-container .centered-content {
    position: absolute;
    max-width: 100%;
}

.engagement-container .engagement-item .centered-content-container {
    background: #000;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.engagement-container .engagement-item .asset-icon {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.engagement-container .engagement-item:hover .centered-content-container,
.engagement-container .engagement-item .centered-content-stage.no-image .centered-content-container {
    opacity: .85;
}

.engagement-container .engagement-item:hover .asset-icon {
    opacity: 0;
}

.photo-carousel .engagment-mask {
    height: 165px;
}

@media (min-width: 640px) {

    /* show up to 9 */
    .engagement-container .engagement-item.item-3,
    .engagement-container .engagement-item.item-4,
    .engagement-container .engagement-item.item-5,
    .engagement-container .engagement-item.item-6,
    .engagement-container .engagement-item.item-7,
    .engagement-container .engagement-item.item-8,
    .engagement-container .engagement-item.item-9 {
        display: block;
    }

    /* hide odd tiles out */
    .engagement-container[data-engagement-total="5"] .engagement-item.item-5,
    .engagement-container[data-engagement-total="7"] .engagement-item.item-7,
    .engagement-container[data-engagement-total="8"] .engagement-item.item-7,
    .engagement-container[data-engagement-total="8"] .engagement-item.item-8,
    .engagement-container[data-engagement-total="10"] .engagement-item.item-10,
    .engagement-container[data-engagement-total="11"] .engagement-item.item-10,
    .engagement-container[data-engagement-total="11"] .engagement-item.item-11 {
        display: none;
    }

    .engagement-container .engagement-item,
    .engagement-container[data-engagement-total="3"] .engagement-item,
    .engagement-container[data-engagement-total="6"] .engagement-item,
    .engagement-container[data-engagement-total="7"] .engagement-item,
    .engagement-container[data-engagement-total="9"] .engagement-item,
    .engagement-container[data-engagement-total="10"] .engagement-item,
    .engagement-container[data-engagement-total="11"] .engagement-item {
        width: 33.3333%;
    }

    .photo-carousel .engagment-mask {
        height: 380px;
    }

}

/* Small devices (tablets, 768px and up) */
@media (min-width: 1200px) {

    /* show all items */
    .engagement-container .engagement-item,
    .engagement-container[data-engagement-total="8"] .engagement-item.item-7,
    .engagement-container[data-engagement-total="8"] .engagement-item.item-8 {
        display: block;
        width: 25%;
    }

    .engagement-container[data-engagement-total="8"] .engagement-item.item-4,
    .engagement-container[data-engagement-total="8"] .engagement-item.item-7,
    .engagement-container[data-engagement-total="12"] .engagement-item.item-4,
    .engagement-container[data-engagement-total="12"] .engagement-item.item-7 {
        clear: none;
    }

    .engagement-container[data-engagement-total="8"] .engagement-item.item-5,
    .engagement-container[data-engagement-total="12"] .engagement-item.item-5,
    .engagement-container[data-engagement-total="12"] .engagement-item.item-9 {
        clear: left;
    }

    .photo-carousel .engagment-mask {
        height: 351px;
    }

}

/* Gallery engagement */
.photo-carousel .item.engagement-slide {
    text-align: left;
}

.photo-carousel .item.engagement-slide h3.engagement-description {
    padding: 5px 0 0 5px;
    margin-bottom: 5px;
    color: #fff;
}

.photo-carousel .engagment-mask {
    background:#000;
    width:100%;
}

.photo-carousel .engagment-mask .engagement-container{
    opacity: 0;
}

.photo-carousel .engagement-container{
    opacity: 1;
    transition: opacity 2s ease;
}


/* Plugins
-------------------------------------------------- */

/* mark.js - highlight search results */
mark {
    background-color: rgb(251,247, 213);
    padding: .2em 0px;
}
/* yamm menu */
.yamm .nav, .yamm .collapse,
.yamm .dropup,
.yamm .dropdown,
.yamm .site-search-full .search-form-dropdown.dropdown {
    position: static;
}

.yamm .container {
    position: relative;
}

.yamm .dropdown-menu {
    left: auto;
}

.yamm .nav.navbar-right .dropdown-menu {
    left: auto;
    right: 0;
}

.yamm .yamm-content {
    padding: 10px 20px;
}

.yamm .dropdown.yamm-fw .dropdown-menu {
    left: 0;
    right: 0;
}

.yamm-content .input-group-btn {
	z-index: 3;
}

/* lazy load */
img.lazy-load {
    opacity: 0;
    transition: opacity .2s ease-in;
}


/* Contribute Wrapper
-------------------------------------------------- */

.panel-contribute {
    position: relative;
}

.panel-contribute > .panel-body {
    padding: 15px 111px 15px 15px;
    min-height: 136px;
}

.contribute-tools {
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: center;
    height: 100%;
    background: #F3F3F3;
}

.contribute-tools .btn {
    width: 96px;
    padding: 6px 8px;
    display: block;
}

.btn-full-xs {
    width: 100%;
}

@media (min-width: 768px) {
    .btn-full-xs {
        width: auto;
    }
}

.btn-contrib,
#webauthn-credentials-container .btn {
    background-color: #E7E7E7;
    color: #333;
    text-align: left;
}

#webauthn-credentials-container .btn {
    font-weight: 400;
}

.contribute-tools .btn-contrib {
    border-top: 1px solid #d4d4d4;
}

.contribute-tools > .btn-contrib:first-child{
    border-top: none;
}

.btn-contrib:hover {
    background-color: #DBDBDB;
}

.contribute-tools .dropdown-menu li a {
    cursor: pointer;
}

@media(max-width:991px){

    .contribute-tools {
        width: auto;
    }

    .contribute-tools .btn {
        width: auto;
        text-align: center;
    }

    .panel-contribute > .panel-body {
        padding: 15px 51px 15px 15px;
    }

}


/* Ads
-------------------------------------------------- */

.tnt-ads-container {
    min-height: 1px; /* allows floated ads to be rendered */
}

.tnt-expandable-ad-wrapper .noOverlay {
    position: relative;
}

.tnt-expandable-ad-wrapper .overlay {
    position: absolute;
}


/* iQ ads
-------------------------------------------------- */

.tncms-iq-ad div {
    margin: 0px auto 40px !important;
    display: block !important;
    clear: both;
    text-align: center;
}

.tnt-text-ad-wrapper {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    position: relative;
}

.tnt-text-ad-wrapper a {
    font-weight: bold;
}

.tnt-text-ad-wrapper .tnt-text-ad-container {
    padding: 10px 10px 25px;
}

.tnt-text-ad-wrapper span.tnt-text-ad-flag {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 4px 0;
    float: right;
    padding: 3px 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .625em;
    text-align: center;
    background: #F0F0F0;
    color: #BBB;
}

#blox-ad-position-_popup1 {
    width: 80%;
    left: 50%;
    min-height: auto;
    position: fixed;
    top: 50%;
    z-index: 10000002;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#blox-ad-position-_popup1.tnt-image-ad-wrapper {
    height: auto;
    width: 80%;
}

#blox-ad-position-_popup1 video {
    max-width: 100%;
    height: auto;
}

.tnt-ads-popup .tnt-svg {
    color: #fff;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -ms-transform: translate(115%, -85%);
    -webkit-transform: translate(115%, -85%);
    -moz-transform: translate(115%, -85%);
    -o-transform: translate(115%, -85%);
    transform: translate(115%, -85%);  
}

.tnt-ads-popup .tnt-text-ad-container {
    background-color: #f0f0f0;
}

#sticky-anchor .tncms-iq-ad div,
#sticky-anchor iframe[id^=html5-ad-frame]{
    margin-bottom: 0 !important;
}


/* Page Curl Ads
-------------------------------------------------- */

.page-curl {
    position: absolute;
    float: right;
    right: 0;
    top: 0;
    z-index: 1038;
}

.cover {
    background-image: url("../../../template/resources/images/curl.d5a4117d2e3331dce7dc80d0e7754607.png");
    background-size: 100% auto;
    height: 75px;
    position: relative;
    right: 0;
    top: 0;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    width: 75px;
    z-index: 1002;
}

.page-curl:hover .cover {
    height: 500px;
    width: 500px;
}

.small-ad, .large-ad {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
}

.small-ad {
    height: 75px;
    right: 0;
    top: 0;
    width: 75px;
    z-index: 1001;
}

.large-ad {
    height: 500px;
    right: -425px;
    top: -425px;
    width: 500px;
    z-index: 999;
}

.small-ad object {
    height: 75px;
    position: relative;
    width: 75px;
}

.large-ad object {
    height: 500px;
    position: relative;
    width: 500px;
}

.page-curl:hover .small-ad {
    height: 75px;
    width: 75px;
    z-index: 998;
    right: -425px;
    top: -425px;
}

.page-curl:hover .large-ad {
    height: 500px;
    right: 0;
    top: 0;
    width: 500px;
    z-index: 999;
}

.pull-right .dfp-ad {
    min-height: 1px !important;
    float:right !important;
}
.pull-left .dfp-ad {
    min-height: 1px !important;
    float: left !important;
}


/* Wallpaper Ads
-------------------------------------------------- */

body.wallpaper-ad {
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.tnt-ads-wallpaper #wallpaper-left,
.tnt-ads-wallpaper #wallpaper-right {
    height: 100%;
    position: fixed;
    top: 0;
}

.tnt-ads-wallpaper #wallpaper-left {
    left: 0;
}

.tnt-ads-wallpaper #wallpaper-right {
    right: 0;
}

.tnt-ads-wallpaper #wallpaper-left a,
.tnt-ads.wallpaper #wallpaper-right a {
    display: block;
    height: 100%;
    width: 100%;
}

.pull-ad-left > .tnt-ads {
    float: left;
}

.pull-ad-right > .tnt-ads {
    float: right;
}

/* Sticky Anchor Region
-------------------------------------------------- */
#sticky-anchor {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    bottom: 0px;
    margin-bottom: 0px !important;
    left: 50%;
    margin-left: -50%;
    width: 100%;
    z-index: 1038;
}

#sticky-anchor .tnt-ads,
#sticky-anchor .tncms-region-ads div:first-child,
#sticky-anchor .tnt-ads-container div:first-child,
#sticky-anchor .ad-placeholder-container .ad-placeholder {
    margin-bottom: 0px !important;
}

#sticky-anchor .card,
#sticky-anchor .panel {
    margin-bottom: 0px;
}

#sticky-anchor .sticky-anchor-close {
    position: absolute;
    right: 0px;
    transform: translate(-100%, -100%);
    -webkit-transform: translate(-100%, -100%);
    -ms-webkit-transform: translate(-100%, -100%);
    background-color: rgba(0,0,0,0.5);
    padding: 3px 7px 0px 7px;
    border-radius: 5px 5px 0 0;
    color: #FFF;
    font-size: 18px;
    cursor: pointer;
}

/* Anchor Ads
-------------------------------------------------- */
.tnt-ads-anchor {
    position: fixed;
    bottom: 0px;
    margin-bottom: 0px !important;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -ms-webkit-transform: translate(-50%, 0%);
    z-index: 1038;
}

.tnt-ads-anchor.alert, .tnt-ads-container.alert {
    border: 0px none;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 0px;
    box-shadow: 0 0 rgba(0, 0, 0, 0);
}

@media (max-width:991px){

    .tnt-ads-anchor img {
        max-width: 310px;
    }

}

.modal.fullscreen .photo-carousel .item .tnt-image-ad-wrapper img {
    top: initial!important;
    transform: initial!important;
    -webkit-transform: initial!important;
    -moz-transform: initial!important;
    -ms-transform: initial!important;
    -o-transform: initial!important;
}

/* Breakout Ads
-------------------------------------------------- */
.asset-breakout-ads {
    background-color: rgba(0,0,0,0.05);
    padding: 20px 0 1px 0;
    margin: 0 calc(50% - 50vw);
    margin-bottom: 30px;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    clear: both;
}

.asset-breakout-ads .tnt-ads-container .tnt-ads {
    margin-bottom: 20px;
}

.fixed-sticky-rail .tnt-ads {
    padding-bottom: 30px;
}

.fixed-sticky-rail-spacer {
    min-height: 250px;
    min-width: 300px;
}

/* Panels
-------------------------------------------------- */

.panel {
    border-radius: 0;
}

/* Access wall
-------------------------------------------------- */

#service-promo-modal .modal-header h3,
#access-offers-modal .modal-header h3{
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
}

.access-methods-list .access-login { 
    margin: 0 15px;
}

.access-methods-list .access-login > * {
    margin-bottom: 20px;
}

.access-methods-list .access-login a.btn {
    width: 100%;

}

.access-methods-list .photo .image img,
#access-offers-modal .method-image .photo .image img{
    object-fit: contain;
    max-width: 80%;
    margin: 0 10%;
    max-height: 120px;
}

#access-modal-user-links {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
    justify-items: center;
}

#access-offers-modal .modal-header .tab-back {
    float: left;
}

#access-offers-modal .subscription-service.panel {
    position:relative;
    margin-top:.5em;
}

#access-offers-modal .method-featured {
    position: absolute;
    top: 0;
    margin-top: -.5em;
    width: 100%;
    text-align: center;
}

#access-offers-modal .method-tagline {
    grid-area: "tagline";
}

#access-offers-modal .method-image {
    grid-area: "image";
}

/** Modal display */
@media screen and (min-width: 768px) {
    #service-promo-modal .modal-dialog,
    #access-offers-modal .modal-dialog.offer-tab{
        width: 85vw;
    }
    
    #service-promo-modal .modal-header h3,
    #access-offers-modal .modal-header h3 {
        font-size: 18px;
    }
    
    #access-modal-user-links {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .access-methods-list .access-login a.btn {
       max-width: 290px;
    }
}

#service-promo-modal.modal-small .modal-dialog,
#access-offers-modal.modal-small .modal-dialog {
    max-width: 800px;
}

/* Login password toggle */
#user-password-toggle {
    cursor: pointer;
}
.input-group-addon.password-toggle {
    min-width: 43px;
}

/** Tiled access method layout */
#access-methods {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

@media screen and (max-width: 768px){
    #access-methods {
        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    }

    #access-methods a.btn-lg{
        font-size: 16px;
    }
}

/** Layout of access method cards */ 
#access-methods .subscription-service {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "header header"
        "body body"
        "footer footer";
}

#modal-offers-tab #access-methods .subscription-service {
    grid-template-areas:
        "header header"
        "tagline tagline"
        "image image"
        "body body"
        "footer footer"
}

#access-methods .method-header {
    grid-area: header;
    align-self: start;
    text-align: center;
}

#access-methods .method-description {
    align-self: stretch;
    grid-area: body;
    padding: 10px;
}

#modal-offers-tab #access-methods .subscription-service .method-description {
    grid-area: none;
}

#modal-offers-tab #access-methods .subscription-service .offer-details {
    grid-area: body;
    max-width: 100%;
    overflow: hidden;
}


#access-methods .method-description ul {
    padding-left: 15px;
}

#access-methods .method-footer {
    grid-area: footer;
    align-self: end;
}

#access-methods .method-footer .btn {
    width: 100%;
}

/* Assets
-------------------------------------------------- */

.cutout {
    background: none !important;
    background-color: transparent !important;
}


/* Articles
-------------------------------------------------- */

/* Base styles used by all article assets */
body.article .asset {}

.asset .asset-content > table, 
.asset .asset-content > div > table {
    width: 100%;
}

.asset .asset-content > .twitter-tweet, 
.asset .asset-content > div > .twitter-tweet {
    margin: 10px auto;
}

.tnt-text-to-speach {
    margin-bottom: 20px;
}

.trinityAudioPlaceholder {
    min-height: 80px;
}

/* Longform */
/* Base styles used by all longform article assets */
.article-longform .asset-header .hammer,
body.article .article-longform .asset-header .kicker,
body.article .article-longform .asset-header h1 {
    margin: 0 20px;
}

.asset.article-longform .asset-body .asset-content > p,
.asset.article-longform .asset-body .asset-content > div > p, 
.asset.article-longform .asset-body div > hr {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.asset.article-longform .share-container.headline-right {
    display: none;
}

.asset.article-longform .share-container.content-above,
.asset.article-longform .share-container.content-below {
    text-align: center;
}

.asset.article-longform .asset-author, 
.asset.article-longform .asset-tags, 
.asset.article-longform .asset-recommend, 
.asset.article-longform .asset-comments,
.asset.article-longform #asset-content > .tnt-ads-container,
.asset.article-longform .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-alignment-default.tnt-inline-width-default,
.asset.article-longform .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-width-full {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    float: none;
}

.asset.article-longform .asset-body div > hr {
    border: 2px solid #222; 
}

.asset.article-longform .asset-content > h1, 
.asset.article-longform .asset-content > div > h1,
.asset.article-longform .asset-content > h2, 
.asset.article-longform .asset-content > div > h2, 
.asset.article-longform .asset-content > h3, 
.asset.article-longform .asset-content > div > h3, 
.asset.article-longform .asset-content > h4, 
.asset.article-longform .asset-content > div > h4, 
.asset.article-longform .asset-content > h5, 
.asset.article-longform .asset-content > div > h5, 
.asset.article-longform .asset-content > h6, 
.asset.article-longform .asset-content > div > h6,
.asset.article-longform .asset-content > ul, 
.asset.article-longform .asset-content > div > ul,
.asset.article-longform .asset-content > ol, 
.asset.article-longform .asset-content > div > ol,
.asset.article-longform .asset-content > dl, 
.asset.article-longform .asset-content > div > dl,
.asset.article-longform .asset-content > table, 
.asset.article-longform .asset-content > div > table,
.asset.article-longform .asset-content > blockquote, 
.asset.article-longform .asset-content > div > blockquote,
.asset.article-longform .asset-content > pre, 
.asset.article-longform .asset-content > div > pre,
.asset.article-longform .asset-content > .twitter-tweet, 
.asset.article-longform .asset-content > div > .twitter-tweet {
    font-weight: 400;
    max-width: 660px;
    margin: 10px auto;
}

.asset.article-longform .asset-content > ul, 
.asset.article-longform .asset-content > div > ul,
.asset.article-longform .asset-content > ol, 
.asset.article-longform .asset-content > div > ol,
.asset.article-longform .asset-content > dl, 
.asset.article-longform .asset-content > div > dl,
.asset.article-longform .asset-content > table, 
.asset.article-longform .asset-content > div > table,
.asset.article-longform .asset-content > blockquote, 
.asset.article-longform .asset-content > div > blockquote,
.asset.article-longform .asset-content > pre, 
.asset.article-longform .asset-content > div > pre,
.asset.article-longform .asset-content > .twitter-tweet, 
.asset.article-longform .asset-content > div > .twitter-tweet {
    margin: 20px auto;
}

@media screen and (min-width: 768px) {

    body.article .article-longform .asset-header .hammer,
    body.article .article-longform .asset-header .kicker,
    body.article .article-longform .asset-header h1 {
        margin: 0 120px;
    }

    .asset.article-longform .asset-body .asset-content > p,
    .asset.article-longform .asset-body .asset-content div.subscriber-preview > p,
    .asset.article-longform .asset-body .asset-content div.subscriber-only > p {
        font-size: 18px;
        line-height: 1.8em;
    }

    .asset.article-longform .asset-body div > hr {
        margin: 40px auto;
    }

}

.article-longform .hidden-long-form {
    display: none;
}

.visible-long-form {
    display: none;
}

.article-longform .visible-long-form {
    display: block;
}

/* Cover art
-------------------------------------------------- */

/* main-page-container needs padding removed. */
#main-page-container.has-cover-art {
    padding-top: 0px;
}

#main-page-container.has-cover-art #top-container-breakout {
    margin-top: 20px;
}

#main-page-container.has-cover-art .col-md-12 {
    min-height: 0px; /* remove whitespace caused by empty rows/regions taking up 1px */
}

.asset .asset-masthead.tnt-cover-art,
.asset .asset-masthead.tnt-cover-art > img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    height: 100vh;
}

.asset .asset-masthead.tnt-cover-art {
    position: relative;
    display: flex;
    margin-top: 0px;
}

.asset .asset-masthead.tnt-cover-art > img {
    top: 0; left: 0; /* for IE */
    position: absolute;
    object-fit: cover;
    font-family: 'object-fit: cover;'; /* IE polyfill */
}

.asset .asset-masthead.tnt-cover-art #covert-art-arrow {
    z-index: 1;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
}

.asset .asset-masthead.tnt-cover-art .asset-header {
    width: 100%;
    text-align: center;
}

.asset .asset-masthead.tnt-cover-art .asset-header .hammer, 
.asset .asset-masthead.tnt-cover-art .asset-header .kicker, 
.asset .asset-masthead.tnt-cover-art .asset-header .subhead, 
.asset .asset-masthead.tnt-cover-art .asset-header .meta, 
.asset .asset-masthead.tnt-cover-art .asset-header h1 {
    margin-left: 0;
    margin-right: 0;
}

.asset .asset-masthead.tnt-cover-art .asset-header .subhead {
    margin: 5px 0;
}

.asset .asset-masthead.tnt-cover-art .asset-header .meta {
    margin-top: 5px;
}

.asset .asset-masthead.tnt-cover-art .social-share-links {
    display: none;
}

/* Cover art asset header */
.asset .asset-masthead.tnt-cover-art .asset-header {
    z-index: 1;
    padding: 20px;
    margin: 25px 0;
}

.asset .asset-masthead.tnt-cover-art.tnt-cover-align-y-bottom .asset-header {
    margin-bottom: 45px;
}

/* Cover headline horizontal alignment */
.tnt-cover-align-x-left {
    justify-content: flex-start;
}

.tnt-cover-align-x-center {
    justify-content: center;
}

.tnt-cover-align-x-right {
    justify-content: flex-end;
}

/* Cover headline vertical alignment */
.tnt-cover-align-y-top {
    align-items: flex-start;
}

.tnt-cover-align-y-middle {
    align-items: center;
}

.tnt-cover-align-y-bottom {
    align-items: flex-end; 
}

.asset-masthead.tnt-cover-art.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span {
    line-height: inherit;
}

.tnt-cover-text-bg-text-in-a-box .asset-header .meta > span {
    display: inline-block;
}

.tnt-cover-text-bg-text-in-a-box .asset-header .meta ul.list-inline {
    margin-bottom: 0px;
}

/* Cover effect */
.asset .asset-masthead.tnt-cover-art .tnt-parallax {
    top: 0; left: 0; /* for IE */
    position: absolute;
    height: 100vh;
}

.tnt-effect-blur img,
img.tnt-effect-blur {
    filter: blur(5px);
}

.tnt-effect-grayscale img,
img.tnt-effect-grayscale {
    filter: grayscale(50%);
}

.tnt-effect-sepia img,
img.tnt-effect-sepia {
    filter: sepia(100%)
}

.tnt-effect-desaturate img,
img.tnt-effect-desaturate {
    filter: grayscale(100%);
}

/* Ken Burns */
.tnt-effect-ken-burns {
    overflow: hidden;
    position: relative;
}

.tnt-effect-ken-burns img {
    position: absolute;
    animation: move 40s ease;
    animation-fill-mode: forwards;
}

@keyframes move {

    0% {
        transform-origin: top left;
        transform: scale(1.0);
    }

    100% {
        transform: scale(1.2);
    }

}

@media (min-width: 768px) {
    .asset .asset-masthead.tnt-cover-art .asset-header {
        width: auto;
        margin: 30px;
    }
}

@media (min-width: 992px) {
    .asset .asset-masthead.tnt-cover-art .asset-header {
        max-width: 50%;
    }
}

/* Poll
-------------------------------------------------- */

.tnt-inline-poll .poll-content {
    padding: 0 10px 10px 10px;
}

.tnt-chart {
    display: flex;
}

.tnt-chart-pie {
    width: 150px;
    margin-right: 15px;
}

.tnt-chart-bar {
    width: 100%;
    height: 20px;
}

.tnt-chart-bar text {
    font-weight: bold;
}

.tnt-chart-bar text.tnt-chart-count {
    display: none;
}

.poll-image {
    margin-bottom: 10px;
}

/* Edit assets
-------------------------------------------------- */
#asset-contribute-bar,
#asset-admin-bar {
    display: none;
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
}

#asset-contribute-bar {
    background-color: #02437C;
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.05) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.05) 50%,rgba(255,255,255,.05) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.05) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.05) 50%,rgba(255,255,255,.05) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,.05) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.05) 50%,rgba(255,255,255,.05) 75%,transparent 75%,transparent);
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px;
    color: #fff;
}

#asset-admin-bar {
    background-color: #222;
    color: #fff;
}

.asset-edit-bar .asset-edit-icon {
    padding: 10px 15px;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    font-size: 20px;
}

.asset-edit-bar .asset-edit-message {
    flex-grow: 1;
    padding: 10px 15px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-shadow: 0 1px 0 rgba(0,0,0,0.5);
}

.asset-edit-bar .asset-edit-btns {
    padding: 10px 15px;
}

.asset-edit-bar .asset-edit-close {
    color: #fff;
}


/* Inline related content
-------------------------------------------------- */

.tncms-inline-relcontent {
    border: 1px solid rgba(0,0,0,.08);
    border-top: 5px solid rgba(0,0,0,.08);
    padding: 20px 20px 0px 20px;
}


/* Inline assets 
-------------------------------------------------- */

.tnt-inline-asset,
.tncms-inline-relcontent,
.inline-asset {
    margin-bottom: 30px;
}

.asset .tnt-inline-asset figure .caption {
    margin-bottom: 0px;
}

.inline-asset.inline-video .tnt-video-container,
.inline-asset.inline-video .youtube-video-container {
    margin-bottom: 10px;
}

/**
 * Small device inline asset alignment 
 */
@media (max-width: 767px) {

    /* left */
    .tnt-inline-image.tnt-inline-alignment-left {
        float: left;
        clear: left;
        margin-right: 30px;
        width: 50%;
    }

    /* right */
    .tnt-inline-image.tnt-inline-alignment-right,
    .tnt-inline-image.layout-vertical.tnt-inline-alignment-default,
    .tnt-inline-image.tnt-asset-presentation-mugshot.tnt-inline-alignment-default {
        float: right;
        clear: right;
        margin-left: 30px;
        width: 50%;
    }

    /* center */
    .tnt-inline-image.tnt-inline-alignment-center,
    .tnt-inline-image.tnt-inline-width-half.tnt-inline-alignment-default {
        float: none;
        clear: both;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    /* full width */
    .tnt-inline-width-full,
    .tncms-inline-width-full,
    .tnt-inline-image.tnt-asset-presentation-panorama,
    .tnt-inline-image.tnt-asset-presentation-zoom {
        float: none;
        clear: both;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
    }

    /* collection mosaic presenation */
    .tnt-inline-presentation-mosaic .mosaic-main-col .mosaic-item-container {
        max-height: 200px;
    }

    .tnt-inline-presentation-mosaic .mosaic-col {
        display: none;
    }

}

/**
 * Inline asset alignment 
 */
@media (min-width: 768px) {
    /** 
     * Inline asset width
     */

    /* half */
    .tncms-inline-width-half,
    .tnt-inline-width-half,
    .tncms-inline-relcontent.tncms-inline-width-half.tncms-inline-alignment-left,
    .tncms-inline-relcontent.tncms-inline-width-half.tncms-inline-alignment-right,
    .tncms-inline-relcontent.tncms-inline-width-half.tncms-inline-alignment-center {
        width: 50%;
    }

    /* full plus items that default to full width when not aligned */
    .tncms-inline-width-full,
    .tnt-inline-width-full,
    .tncms-inline-relcontent.tncms-inline-width-full.tncms-inline-alignment-left,
    .tncms-inline-relcontent.tncms-inline-width-full.tncms-inline-alignment-right,
    .tncms-inline-relcontent.tncms-inline-width-full.tncms-inline-alignment-center,
    .tnt-inline-image.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-image.tnt-inline-presentation-presentation.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-image.tnt-inline-presentation-fullbleed.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-collection.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-collection.tnt-inline-presentation-presentation.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-collection.tnt-inline-presentation-mosaic.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-audio.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-audio.tnt-inline-presentation-player.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-flash.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-html.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-html.tnt-inline-presentation-html.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-link.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-pdf.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-pdf.tnt-inline-presentation-pdf.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-presentation-short-summary.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-table.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-table.tnt-inline-presentation-table.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-video.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-video.tnt-inline-presentation-player.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-youtube.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-youtube.tnt-inline-presentation-player.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-presentation-headline.tnt-inline-width-default.tnt-inline-alignment-default {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    /* default width */
    .tnt-inline-width-default,
    .tncms-inline-relcontent.tncms-inline-alignment-left,
    .tncms-inline-relcontent.tncms-inline-alignment-right,
    .tncms-inline-relcontent.tncms-inline-alignment-center,
    .tnt-inline-image.layout-vertical.tnt-inline-presentation-default.tnt-inline-width-default,
    .tnt-inline-image.layout-vertical.tnt-inline-presentation-presentation.tnt-inline-width-default,
    .tnt-inline-image.tnt-asset-presentation-mugshot.tnt-inline-presentation-default.tnt-inline-width-default,
    .tnt-inline-image.tnt-asset-presentation-mugshot.tnt-inline-presentation-presentation.tnt-inline-width-default {
        width: 35%;
    }

    /* left */
    .tncms-inline-alignment-left,
    .tnt-inline-alignment-left {
        float: left;
        clear: left;
        margin-right: 30px;
    }

    /* right and default alignment */
    .tncms-inline-alignment-right,
    .tnt-inline-alignment-right,
    .tnt-inline-alignment-default,
    .tnt-inline-image.layout-vertical.tnt-inline-presentation-default.tnt-inline-width-default.tnt-inline-alignment-default,
    .tnt-inline-image.tnt-asset-presentation-mugshot.tnt-inline-width-default.tnt-inline-alignment-default {
        float: right;
        clear: right;
        margin-left: 30px;
    }

    .article-longform .tnt-inline-alignment-left.tnt-inline-width-default,
    .article-longform .tncms-inline-relcontent.tncms-inline-alignment-left {
        margin-left: 10%;
    }

    .asset.article-longform .tnt-inline-alignment-right.tnt-inline-width-default,
    .asset.article-longform .tncms-inline-relcontent.tncms-inline-alignment-right,
    .asset.article-longform .tnt-inline-relation-sibling.tnt-inline-presentation-headline.tnt-inline-alignment-default.tnt-inline-width-default,
    .asset.article-longform .tnt-inline-presentation-summary.tnt-inline-alignment-default.tnt-inline-width-default {
        margin-right: 10%;
    }

    /* center plus items that default to center align when not default width */
    .tncms-inline-alignment-center,
    .tnt-inline-alignment-center,
    .tnt-inline-image.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-collection.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-audio.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-flash.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-html.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-link.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-pdf.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-table.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-video.tnt-inline-width-half.tnt-inline-alignment-default,
    .tnt-inline-youtube.tnt-inline-width-half.tnt-inline-alignment-default {
        float: none;
        clear: both;
        margin-left: auto;
        margin-right: auto;
    }

}

/* full viewport height */
.tnt-height-full-viewport {
    height: 100vh;
}

/* full viewport width */ 
.tnt-width-full-viewport {
    width: 100vw;
    clear: both;
    position: relative;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}

.tnt-width-full-viewport .caption {
    padding: 0 25px;
}

/**
 * Inline asset presentation 
 */

/* headline link */
.tnt-inline-presentation-headline a {
    line-height: 27px;
}

/* parallax image container */
.tnt-parallax {
    min-width: 100%;
    height: 250px;
    margin-bottom: 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
}

.inline-asset.tnt-inline-presentation-short-summary .card .card-container > .card-image {
    width: 20%;
    float: left;
}
.inline-asset.tnt-inline-presentation-short-summary .card.has-image > .card-container > .card-body {
    margin-left: 20%;
    padding-bottom: 0px;
    padding-top: 0px;
}

.inline-asset.tnt-inline-presentation-short-summary .card {
    border-left: 0px;
    border-right: 0px;
    padding: 10px 0;
}

.asset .asset-body .inline-asset.tnt-inline-presentation-short-summary  ul {
    margin-bottom: 0px;
}

@media (min-width: 768px) {

    .tnt-parallax {
        height: 600px;
    }

    .asset.article-longform .tnt-inline-presentation-headline a {
        line-height: 1.8em;
    }

    .inline-asset.tnt-inline-presentation-short-summary .card .card-container > .card-image {
        width: 15%;
    }

    .inline-asset.tnt-inline-presentation-short-summary .card.has-image > .card-container > .card-body {
        margin-left: 15%;
    }

    .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-alignment-left .card .card-container > .card-image,
    .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-alignment-right .card .card-container > .card-image {
        width: 43%;
    }

    .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-alignment-left .card.has-image > .card-container > .card-body,
    .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-alignment-right .card.has-image > .card-container > .card-body {
        margin-left: 43%;
    }

    .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-width-half .card .card-container > .card-image {
        width: 30%;
    }

    .inline-asset.tnt-inline-presentation-short-summary.tnt-inline-width-half .card.has-image > .card-container > .card-body {
        margin-left: 30%;
    }

}
@media (max-width: 1024px) {

    .tnt-parallax {
        background-attachment: scroll;
    }

}


/* Collections
-------------------------------------------------- */

/* Series */
.series-stream.jumbotron {
    padding: 15px;
}

.collection-content.series .stream-count {
    margin-left: 7px;
}

.stream-container.stream-sm .stream-count {
    margin-left: 1px;
}

.collection-content.series .panel-body.business {
    padding: 0px 0px 0px 15px;
}

.collection-content.series .panel-body.business .card {
    border: none;
}

/* Series slide box */
#slidepanel{
    max-width: 400px;
    height: auto;
    position: fixed;
    bottom: 0px;
    right: -430px;
    z-index: 1;
    transition: right 300ms ease-in-out;
}

#slidepanel.open {
    right: 0;
}

#slidepanel .panel-body p {
    padding-right: 15px;
}

#slidepanel a.close{
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}


/* Classified ads
-------------------------------------------------- */

.classifieds-nav.navbar.navbar-default {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.classifieds-nav .navbar-brand {
    padding: 10px 16px 10px 0;
    margin-bottom: 8px;
}

.card-classifieds-links a {
    opacity: .8;
    color: #222;
}

.card-classifieds-links a:hover {
    opacity: 1;
    color: #222;
    text-decoration: none;
}

.virtual-tour-link {
    margin-bottom: 20px;
}


/* Button rows
-------------------------------------------------- */

.button-row {
    width: 100%;
    margin-left: -2px;
    margin-right: -2px;
}

.button-row > div {
    padding-left: 2px;
    padding-right: 2px;
}


/* Classified landing
-------------------------------------------------- */

.app-classifieds .cls-info-content {
    padding: 15px;
}

.app-classifieds .asset .asset-body ul.social-share-links {
    margin-bottom: 0;
}

.app-classifieds #asset-pdf-display {
    margin-bottom: 20px;
}

.app-classifieds .asset-body .cls-info-tabs {
    padding-bottom: 5px;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    margin-bottom: 10px;
}

.app-classifieds .cls-info-tabs > li > a {
    font-weight: bold;
}

.app-classifieds .cls-info-tabs > li > a > h4 {
    margin: 0;
}

@media print {

    .app-classifieds .cls-info-tabs {
        display: none;
    }

    .app-classifieds .cls-info-content > .tab-pane {
        display: block;
        opacity: 1;
        visibility: visible;
    }

}

.app-classifieds .asset-carfax-check {
    margin-bottom: 25px;
}

.app-classifieds .asset-price-bar {
    background-color: rgba(0,0,0,0.03);
    padding: 10px;
    border-top: 1px solid rgba(0,0,0,0.08);
    border-bottom: 1px solid rgba(0,0,0,0.08);
    margin-bottom: 25px;
    text-align: center;
}

.app-classifieds .asset-price-bar h2 {
    margin-bottom: 0;
}

.app-classifieds .asset-price-bar .asset-price {
    display: inline-block;
    vertical-align: middle;
    margin: 0 2px;
}

.app-classifieds .asset-price-bar .btn {
    vertical-align: middle;
    margin: 0 2px;
}

.app-classifieds .asset-pdf-display {
    padding-bottom: 20px;
}

.app-classifieds .asset .asset-body .cls-fields {
    margin-bottom: 0;
}

.app-classifieds .cls-fields li {
    width: 100%;
    float: left;
    min-height: 30px;
}

.app-classifieds .cls-fields dl.dl-info {
    font-size: 16px;
}

.app-classifieds .cls-fields dl.dl-info dt {
    font-weight: bold;
    font-size: 14px;
}

.app-classifieds .cls-fields dl.dl-info dd {
    word-wrap: break-word;
}

.app-classifieds .tab-pane-features li {
    width: 100%;
    float: left;
    min-height: 30px;
}

.app-classifieds .feature-group {
    margin-bottom: 20px;
}

.app-classifieds .agent-info h4 {
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    margin-bottom: 10px;
}

.app-classifieds .agent-info ul {
    margin-bottom:30px;
}

.app-classifieds .cls-openhouses {
    padding-left: 20px;
}
.app-classifieds .cls-openhouses li {
    margin-bottom: 5px;
}

.asset-openhouse-container {
    margin-bottom: 30px;
    position: relative;
}
.asset-openhouse-container .asset-openhouse-icon {
    position: absolute;
    top: 0px;
    left: 0px;
}
.asset-openhouse-container .asset-openhouse-icon i,
.asset-openhouse-container .asset-openhouse-icon .tnt-svg {
    font-size: 36px;
}
.asset-openhouse-container .asset-openhouse-info {
    padding-left: 48px;
}
.asset-openhouse-container .asset-openhouse-info h4 {
    margin-top: 0px;
    margin-bottom: 5px;
}

@media screen and (min-width: 480px) {

    .app-classifieds .tab-pane-features li,
    .app-classifieds .cls-fields li {
        width: 49%;
    }

}

@media screen and (min-width: 768px) {

    .app-classifieds .tab-pane-features li,
    .app-classifieds .cls-fields li {
        width: 33%;
    }

}


/* Classifieds search
-------------------------------------------------- */
.app-classifieds .results-text-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
}

.cls-other-facet-cats {
    margin-bottom: 16px;
}

.cls-other-facet-cats a {
    margin-bottom: 4px;
}

.cls-other-facet-cats a .badge {
    font-size: 11px;
    background-color: #777;
    padding: 2px 6px;
}

.classifieds-filter-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    clear: both;
}

.classifieds-filter-options .classified-search-options {
    margin-bottom: 14px;
}


/* Classifieds facets
-------------------------------------------------- */

.classifieds-facet-search-form .form-group {
    margin-bottom: 20px;
}

.classifieds-facet-search-form .radio,
.classifieds-facet-search-form .checkbox {
    margin-top: 2px;
    margin-bottom: 2px;
}

.classifieds-facet-search-form .facet-checkboxes-show {
    margin-top: 5px;
}

.classifieds-facet-search-form .facet-checkbox {
    margin-bottom: 0;
}

.classifieds-facet-search-form .facet-checkbox li {
    width: 100%;
    float: left;
    min-height: 20px;
}

@media screen and (min-width:480px) {

    #classifieds-facet-search-form-modal .facet-checkbox li {
        width: 49%;
    }

}
@media screen and (min-width:768px) {

    #classifieds-facet-search-form-modal .facet-checkbox li {
        width: 33%;
    }

    #classifieds-facet-search-form-modal .facet-checkbox.facet-vehicle-features li,
    #classifieds-facet-search-form-modal .facet-checkbox.facet-amenities li {
        width: 49%;
    }

}

.classifieds-facet-search-form .facet-range div {
    display: inline-block;
}

.classifieds-facet-search-form .facet-range-input-container {
    width: 33%;
}

.classifieds-facet-search-form .facet-range-text-container {
    width: 16%;
    text-align: center;
}

.classifieds-facet-search-form .facet-range-btn-container {
    width: 18%;
    vertical-align: top;
    margin-left: -1px;
}

#classifieds-facet-search-form-modal .facet-range-input-container {
    width: 40%;
}

#classifieds-facet-search-form-modal .facet-range-text-container {
    width: 8%;
    text-align: center;
}

#classifieds-facet-search-form-modal .facet-range-btn-container {
    width: 12%;
    vertical-align: top;
    margin-left: -1px;
}

@media screen and (min-width:480px) {

    #classifieds-facet-search-form-modal .facet-range-input-container {
        width: 42%;
    }

    #classifieds-facet-search-form-modal .facet-range-text-container {
        width:8%;
    }

    #classifieds-facet-search-form-modal .facet-range-btn-container {
        width: 8%;
    }

}

.classifieds-facet-search-container input[type=number]::-webkit-outer-spin-button,
.classifieds-facet-search-container input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.classifieds-facet-search-container input[type=number] {
    -moz-appearance: textfield;
}

.app-classifieds .facets-whiteout {
    display: none;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.65;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1998;
    overflow: hidden;
}

.app-classifieds .facets-spinner-container {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -45px;
    margin-top: -35px;
    z-index: 1999;
}

.classifieds-filters button {
    margin-bottom: 4px;
}

.classifieds-filters button svg.tnt-svg {
    font-size: 14px;
}

.classifieds-categories .sub-cat {
    margin-left: 12px;
}

.classifieds-categories li {
    margin-bottom: 5px;
}

.classifieds-categories li.active {
    font-weight: bold;
}

.classified-search-options {
    margin-bottom: 20px;
}

.classifieds-selected-filters {
    padding-bottom: 10px;
}

.refine-modal .refine-group {
    margin-bottom: 30px;
}

.refine-modal .refine-group-heading {
    height: 18px;
    margin-bottom: 0px;
}

.refine-modal .refine-group-heading::after {
    content: '';
    display: block;
    border-top: 1px solid #ddd;
    width: 100%;
    height: 18px;
    margin-top: -10px;
}

.refine-modal .refine-group-heading a {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    display: block;
    padding-right: 20px;
    position: relative;
    color: #333;
}

.refine-modal .refine-group-heading a span {
    background-color: #fff;
    padding-right: 10px;
}

.refine-modal .refine-group-heading a div {
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 10px;
    background-color: #fff;
}

.refine-modal .refine-content {
    margin-top: 10px;
}

.refine-modal .refine-content ul,
.refine-modal .refine-content ul li:last-child {
    margin-bottom: 0;
}


/* Blocks
-------------------------------------------------- */

/* Base styles used by all blocks */
#main-page-container .block,
body.modal-doc .block {
    margin-bottom: 20px;
}

/* block titles */
.block-title h1,
.block-title h2,
.block-title h3,
.block-title h4,
.block-title h5,
.block-title h6 {
    margin: 0;
}

/* block note */
.block-note {
    margin-bottom: 20px;
}

.block-title .block-title-inner {
    display: inline-block;
}

.block.heading-color .block-title-inner {
    padding: 10px 20px;
}

.block-title.light > .block-title-inner > * {
    color: rgba(255,255,255,.8);
}

.block-title.light > .block-title-inner > * > small,
.block-title.light > .block-title-inner > * > a {
    color: rgba(255,255,255,.8);
}

.block-title.dark > .block-title-inner > * {
    color: rgba(0,0,0,.8);
}

.block-title.dark > .block-title-inner > * > small,
.block-title.dark > .block-title-inner > * > a {
    color: rgba(0,0,0,.8);
}

/* Block overline */
.block-overline {
    margin-bottom: 10px;
}

.block-overline h5 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}

.block-overline.dark h5 {
    color: rgba(0,0,0,0.4);
}

.block-overline.light h5 {
    color: rgba(255,255,255,0.5);
}

.block-overline .block-overline-inner {
    display: inline-block;
    border-radius: 0.25em;
}

.block-overline h5 i {
    width: 1.25em;
    display: inline-block;
}

/* block text color */
.block .calendar-time,
.block .calendar-time time {
    color: #777;
}

.block.dark a,
.block.dark a:hover,
.card.dark a,
.card.dark a:hover {
    color: #222;
}

.block.dark,
.card.dark,
.asset .asset-body .block.dark p,
.asset #asset-content .block.dark p,
.asset .asset-body .card.dark p,
.asset #asset-content .card.dark p {
    color: rgba(0,0,0,.7);
}

.block.dark .text-muted, 
.block.dark .card h5 small,
.block.dark .card .calendar-time,
.block.dark .card .calendar-time time,
.card.dark .text-muted, 
.card.dark h5 small,
.card.dark .calendar-time,
.card.dark .calendar-time time {
    color: rgba(0,0,0,.5);
}

.block.dark .card .content-overlay,
.block.dark .card .content-overlay a,
.block.dark .card .content-overlay a:hover,
.card.dark .content-overlay,
.card.dark .content-overlay a,
.card.dark .content-overlay a:hover {
    color: #222;
    z-index: 100;
}

.block.text-shadow .block-title.dark,
.block.dark.text-shadow .card,
.text-shadow .card.dark {
    text-shadow: 0 1px 3px rgba(255,255,255,.6)
}

.block.light .card a,
.block.light .card a:hover,
.card.light a,
.card.light a:hover,
.block.light .card .label-flag,
.card.light .label-flag {
    color: rgba(255,255,255,.8);
}

.block.light .card,
.card.light,
.asset .asset-body .block.light p,
.asset #asset-content .block.light p,
.asset .asset-body .card.light p,
.asset #asset-content .card.light p {
    color: rgba(255,255,255,.7);
}

.block.light .card .text-muted, 
.block.light .card h5 small,
.block.light .card .calendar-time,
.block.light .card .calendar-time time,
.card.light .text-muted, 
.card.light h5 small,
.card.light .calendar-time,
.card.light .calendar-time time {
    color: rgba(255,255,255,.6);
}

.block.light .card .content-overlay,
.block.light .card .content-overlay a,
.block.light .card .content-overlay a:hover,
.card.light .content-overlay,
.card.light .content-overlay a,
.card.light .content-overlay a:hover{
    color: rgba(255,255,255,1);
    z-index: 100;
}

.block.text-shadow .block-title.light,
.block.text-shadow .card,
.block.light.text-shadow .card,
.block.light .card .fa-inverse,
.text-shadow .card.light,
.card.light .fa-inverse {
    text-shadow: 0 1px 3px rgba(0,0,0,.6)
}

.block.light .card .tnt-inverse,
.card.light .tnt-inverse {
    -webkit-filter: drop-shadow(0 1px 1px rgba(0,0,0,.6));
    filter: drop-shadow(0 1px 1px rgba(0,0,0,.6));
}

/* share icon light/dark */
.light .social-share-links .fa-stack-2x,
.dark .social-share-links .fa-stack-2x,
.dark .social-share-links .fa-inverse {
    text-shadow: none;
}

.light .social-share-links .fa-inverse {
    text-shadow: 0 1px 3px rgba(0,0,0,1);
}

.light .social-share-links .tnt-inverse {
    -webkit-filter: drop-shadow(0 1px 1px rgba(0,0,0,1));
    filter: drop-shadow(0 1px 1px rgba(0,0,0,1));
}

.follow-links li a:hover {
    background: #999;
}

/* outbrain native ad text color */
/* dark */
.card.outbrain-widget.dark,
.card.outbrain-widget.dark .text-muted,
.card.outbrain-widget .label-flag.dark {
    color: rgba(0,0,0,.7);
}

.card.outbrain-widget.dark a,
.card.outbrain-widget.dark a:hover {
    color: #222;
}

/* light */
.card.outbrain-widget.light,
.card.outbrain-widget.light .text-muted,
.card.outbrain-widget .label-flag.light,
.card.outbrain-widget.light a,
.card.outbrain-widget.light a:hover {
    color: rgba(255,255,255,.8);
}

.card.mosaic-item.outbrain-widget.light a {
    color: #fff;
}

.card.outbrain-widget .label-flag.dark,
.card.outbrain-widget .label-flag.light {
    text-shadow: none;
}

@media screen and (min-width: 768px) {
    #main-page-container .block {
        margin-bottom: 40px;
    }
    
    .card-grid .block-content-discovery .page .content,
    .card-my-local .my-local-base-set,
    .card-my-local .ts-wrapper,
    .card-grid.continue-watching,
    .tnt-recommendations.tnt-grid {
        display: -ms-grid;
        display: grid;
        grid-gap: 5px 30px;
        word-break: break-word;
    }
    
    .card-my-local .my-local-base-set,
    .card-grid .block-content-discovery .page .content {
        grid-gap: 20px 30px;
    }
    
    .card-my-local .my-local-base-set,
    .card-grid .block-content-discovery .page {
        padding-bottom: 20px;
    }
}

.tnt-recommendations.tnt-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* Cards
-------------------------------------------------- */

.card {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.card .card-container > .card-image {
    overflow: hidden;
    position: relative;
}

.card.has-image > .card-container > .card-body {
    padding-top: 10px;
}

.card .card-image .photo {
    margin: 0;
}

.card .card-image-group {
    position: relative;
    margin-bottom: 10px;
}

.card .card-image img,
.photo img.full,
.tnt-prop-img {
    width: 100%;
}

.photo img.true-size{
    margin: 0 auto;
}

.photo.layout-vertical img.letterbox {
    width: 100%;
    margin: 0 auto;
}

.card .card-image .photo.layout-vertical img {
   /* letterboxed img */
   max-height: 200px;
   width: auto;
   margin: 0 auto;
}

.card .card-image .photo.layout-vertical {
    text-align: center;
    overflow: hidden;
}

.card .card-image .photo.layout-vertical.letterbox-style-blur {
    background-color: transparent;
}

.card .card-image .photo.layout-vertical,
.card .card-image .photo.layout-vertical a {
    display: block;
    width: 100%;
}

.card .card-labels {
    display: none;
}

.tnt-divider::before {
    content: "\2022"; /* Bullet */
    padding: 0px 5px;
}

.card .card-meta .cm {
    display: none;
}

.card-bg-color .card .card-image .photo {
    margin-bottom: 0;
}

/* Mosaic card */
.mosaic-grid > div {
    overflow: hidden;
}

/* Mosaic standard image with margins */
.mosaic-grid .mosaic-item-container .mosaic-item,
.mosaic-grid .mosaic-item-container img {
    height: 185px;
    margin-bottom: 0;
}

/* Mosaic standard image */
.mosaic-grid.row.row-seamless .mosaic-item-container .mosaic-item,
.mosaic-grid.row.row-seamless .mosaic-item-container img {
    height: 200px;
}

/* Mosaic large image */
.mosaic-grid.row.row-seamless .mosaic-item-container-lg .mosaic-item,
.mosaic-grid.row.row-seamless .mosaic-item-container-lg img,
.mosaic-grid .mosaic-item-container-lg .mosaic-item,
.mosaic-grid .mosaic-item-container-lg img {
    height: 400px;
    margin-bottom: 0;
}

.mosaic-grid .mosaic-item-container {
    margin-bottom: 30px;
    position: relative;
    background-color: #231f20;
    overflow: hidden;
    color: #fff;
}

.mosaic-grid.row-seamless .mosaic-item-container {
    margin-bottom: 0px;
}

.block.dark .mosaic-grid .mosaic-item-container {
    color: #222;
}

.mosaic-grid .mosaic-item {
    transition: all .5s;
}

.mosaic-grid .mosaic-item.mosaic-hover-zoom:hover,
.mosaic-grid .mosaic-item.mosaic-hover-brighten-zoom:hover {
    -ms-transform: scale(1.04); /* IE 9 */
    -webkit-transform: scale(1.04); /* Chrome, Safari, Opera */
    transform: scale(1.04);
}

.mosaic-item-container img {
    object-fit: cover;
    object-position: 50% 20%;
    width: 100%;
}

/* Breaking */
.breaking-content .tnt-slider-item .card-body {
    margin-right: 55px;
}

/** 
 * lazysizes object-fit plugin companion CSS
 * currently only needed/applied for mosiac block IE11
 */
.mosaic-item-container .image .tnt-asset-link {
    display: block;
    position: relative;
}
.mosaic-item-container .image .tnt-asset-link:before {
    display: block;
    width: 100%;
    content: "";
    padding-bottom: 100%;
    height: 0;
}
.mosaic-item-container .image .tnt-asset-link img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transition: 400ms transform;
    font-family: "object-fit: cover";
} /* end polyfill */

.mosaic-grid .mosaic-item.card a,
.mosaic-grid .mosaic-item.card a:hover,
.mosaic-grid .mosaic-item.card .card-label-section {
    position: relative;
    color: #fff;
}

.block.dark .mosaic-grid .mosaic-item.card a,
.block.dark .mosaic-grid .mosaic-item.card a:hover,
.block.dark .mosaic-grid .mosaic-item.card .card-label-section {
    color: #222;
}

.mosaic-grid .mosaic-item a:hover {
    text-decoration: none;
}

.mosaic-grid .mosaic-item .full-link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.mosaic-grid .mosaic-item .meta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.block.dark .mosaic-grid .mosaic-item .meta {
    text-shadow: 0 0 5px rgba(255,255,255,0.5);
}

.mosaic-grid .mosaic-item .full-dark-container {
    background-color: #231f20;
    opacity: .5;
    transition: opacity 0.5s ease;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.mosaic-grid .mosaic-item.mosaic-hover-brighten:hover .full-dark-container,
.mosaic-grid .mosaic-item.mosaic-hover-brighten-zoom:hover .full-dark-container {
    opacity: 1;
}

.mosaic-grid .mosaic-item.mosaic-overlay-fade .meta {
    padding-top: 3em;
}

.mosaic-grid .mosaic-item.mosaic-overlay-fade .meta .fade-container {
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 50%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 50%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: opacity 0.5s ease;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
}

.mosaic-grid .mosaic-item.mosaic-overlay-fade.mosaic-hover-brighten:hover .meta .fade-container,
.mosaic-grid .mosaic-item.mosaic-overlay-fade.mosaic-hover-brighten-zoom:hover .meta .fade-container {
    opacity: 0;
}

.mosaic-grid .mosaic-item.mosaic-overlay-fade .ob-api-what {
    position: relative;
}

.outbrain-widget.mosaic-item .card-label-section {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mosaic-grid .mosaic-item .meta .card-labels .label-flag {
    text-shadow: none;
    position: relative;
}

.mosaic-grid .mosaic-item h2,
.mosaic-grid .mosaic-item h3 {
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 1em;
}

.mosaic-grid .mosaic-item h3 {
    font-size:20px;
}

.collection-content-container .mosaic-grid .mosaic-item .tnt-headline {
    padding: 0px 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.mosaic-grid .mosaic-item .byline,
.mosaic-grid .mosaic-item .section,
.mosaic-grid .mosaic-item .section a,
#asset-content .mosaic-grid .mosaic-item .byline {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #eee;
}

.block.dark .mosaic-grid .mosaic-item .byline,
.block.dark .mosaic-grid .mosaic-item .section,
.block.dark .mosaic-grid .mosaic-item .section a {
    color: #333;
}

.mosaic-grid .mosaic-item.mosaic-overlay-textbox .meta .headline a {
    text-shadow: none;
    padding: 1px 0;
    line-height: 1.2em;
    color: #fff;
    background-color: rgba(0,0,0,0.7);
    box-shadow: 5px 0 0 rgba(0,0,0,0.7), -5px 0 0 rgba(0,0,0,0.7);
}

.block.dark .mosaic-grid .mosaic-item.mosaic-overlay-textbox .meta .headline a {
    color: #222;
}

.mosaic-item .label-flag-default {
    color: #fff;
    background-color: rgba(0,0,0,.5);
}

.block.dark .mosaic-item .label-flag-default {
    color: #222;
    background-color: rgba(255,255,255,0.5);
}

.mosaic-item .asset-icon {
    bottom: inherit !important;
    top: 10px;
}

/* My local */
.card-my-local .card-panel.panel,
.card-my-local .card {
    margin-bottom: 0px;
}

.card-my-local select.my-local-select {
    visibility: hidden;
}

.card-my-local .my-local-select {
    cursor: pointer;
    min-height: 36px;
}

.card-my-local .plugin-clear_button.single .clear-button {
    color: #595959;
    font-size: 2.5rem;
    right: 3.5rem;
}

.card-my-local .ts-control {
    padding: 8px 8px 8px 30px;
}

.card-my-local .ts-control-icon {
    position: relative;
    top: 28px;
    left: 10px;
    z-index: 9;
    color: #ccc;
}

.card-my-local .ts-wrapper.input-active .ts-control,
.card-my-local .ts-wrapper .ts-control:hover {
    border-color: #278ecb;
}

/* Dropdown arrow */
.card-my-local .ts-wrapper .ts-control:after {
    cursor: pointer;
    border-color: grey transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: -3px;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 0;
}

.card-my-local .ts-wrapper.dropdown-active .ts-control:after {
    border-color: transparent transparent grey;
    border-width: 0 5px 5px;
    margin-top: -4px;
}

.card-my-local .tnt-ads,
.card-my-local .tnt-ads-container > div:first-child {
    margin-bottom: 0px;
}

/* Feature card */
.card.feature.feature-overlay-full-dark .full-dark-container {
    width: 100%;
    height: 100%;
    background-color: #232323;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.card.feature .stage-wrapper {
    overflow: hidden;
}

.card.feature.feature-hover-zoom .centered-content-stage,
.card.feature.feature-hover-brighten .centered-content-stage,
.card.feature.feature-hover-brighten-zoom .centered-content-stage,
.card.feature.feature-overlay-full-dark .full-dark-container {
    transition: all .5s;
}

.card.feature.feature-hover-zoom .centered-content-stage:hover,
.card.feature.feature-hover-brighten-zoom .centered-content-stage:hover {
    transform: scale(1.04);
}

.card.feature.feature-overlay-full-dark.feature-hover-brighten-zoom .centered-content-stage:hover .full-dark-container,
.card.feature.feature-overlay-full-dark.feature-hover-brighten .centered-content-stage:hover .full-dark-container,
.card.feature.feature-overlay-fade.feature-hover-brighten-zoom .centered-content-stage:hover .centered-content-container > a,
.card.feature.feature-overlay-fade.feature-hover-brighten .centered-content-stage:hover .centered-content-container > a {
    opacity: 0;
}

.card.feature.feature-overlay-textbox .card-headline a {
    background-color: rgba(0,0,0,0.7);
    box-shadow: 5px 0 0 rgba(0,0,0,0.7), -5px 0 0 rgba(0,0,0,0.7);
    text-shadow: none;
    padding: 1px 0;
    line-height: 1.2em;
}

.card.feature.feature-overlay-fade .centered-content-container.vert-align-center > a,
.card.feature.feature-overlay-fade .centered-content-container.vert-align-bottom > a {
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	z-index: 99;
}
.card.feature.feature-overlay-fade .centered-content-container.vert-align-top > a {
    background: -moz-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	z-index: 99;
}
.card.feature.feature-overlay-fade .centered-content-container > a {
    opacity: 1;
    transition: opacity 0.5s;
}
.card.feature.feature-overlay-fade .centered-content-container .centered-content {
    position: relative;
}

.card.feature .card-social {
    position: absolute;
    bottom: 0;
    right: 15px;
}

.card.feature .card-label-section, 
.card.feature .card-label-flags {
    display: inline-block;
}

.card.feature .card-label-section {
    vertical-align: bottom;
    margin: 0 5px;
}

.card.feature .label-flag-default {
    color: #fff;
    text-shadow: none;
    background-color: rgba(0,0,0,.5);
}

.card.feature .text-muted {
    color: rgba(255,255,255,.8);
}

.card.feature .centered-content-stage.no-image {
    display: block;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    background: #212121;
}

.card.feature .centered-content-container {
    text-align: center;
    padding: 15px;
}

.card.feature .centered-content {
    z-index: 999;
    width: 99%;
}

.card.feature .img-responsive {
    width: 100%;
}

.card.feature .centered-content-container.vert-align-top {
    bottom: auto;
    padding-top: 10px;
}

.card.feature .centered-content-container.vert-align-bottom {
    top: auto;
    padding-bottom: 30px;
}

@media screen and (min-width: 768px) {

    .card.feature .centered-content {
        max-width: 80%;
    }

}

#sticky-anchor .card.feature.no-image {
    background: #212121;
}

#sticky-anchor .card.feature .centered-content-stage {
    display: contents;
}

#sticky-anchor .card.feature .content-overlay {
    position: relative;
}

#sticky-anchor .card.feature .image {
    position: absolute;
    width: 100%;
}

/* Showcase */
.card.showcase .showcase-box {
    position: relative;
    width: 49%; /* initial width */
    margin: .5%;
}

.card.showcase .card-container > .card-image,
.card.showcase .card-container .card-body > .card-image {
    width: 100%;
    margin-bottom: 10px;
}

.card.showcase .card-image.mugshot .photo.layout-vertical img {
    height: 220px;
}

/* Showcase image asset */
.asset .asset-showcase-image {
    min-height: 90px;
}

.asset .asset-showcase-image,
.asset .asset-masthead-image,
.asset .panorama-photos .panorama-container,
.asset .zoom-photos .zoom-container {
    position: relative;
    margin-bottom: 30px;
}

.asset .asset-showcase-image .showcase-title,
.caption-overlay.caption {
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.7);
    color: #fff;
    font-size: 13px;
    line-height: 1.4em;
}

.tnt-width-full-viewport .caption-overlay.caption {
    padding: 0px;
}

.caption-overlay.caption .caption-inner {
    padding: 10px 10px 5px
}

.tnt-width-full-viewport .caption-overlay.caption .caption-inner {
    padding-right: 20px;
    padding-left: 20px;
}

.caption-overlay.caption .credit {
    margin-bottom: 5px;
}

.asset .asset-showcase-image img {
    width: 100%;
}

.caption-overlay.caption {
    bottom: 0;
}

.asset .asset-showcase-image .showcase-title {
    top: 0;
    text-align: center;
}

@media screen and (min-width: 768px) {

    /* showcase caption */
    .caption-overlay .caption-inner.collapsed.collapse { 
       display: block;
    }

    .asset .asset-showcase-image .showcase-title, 
    .caption-overlay.caption {
        position: inherit;
        background-color: transparent;
        color: #666;
    }

    .asset-showcase-image .caption-overlay.caption .credit {
        color: #999;
    }

    .caption-overlay .caption-toggle {
        display: none;
    }

    .caption-overlay.caption .caption-inner {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    /* showcase vertical */
    .card.showcase .card-image.mugshot .photo.layout-vertical img {
        height: auto;
        min-height: 220px;
        max-height: 420px;
    }

}

@media screen and (min-width: 1200px) {

    .asset .asset-showcase-image.tnt-width-full-viewport {
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }

    .asset-showcase-image.tnt-width-full-viewport .caption-overlay.caption .caption-inner {
        padding-left: 0px;
        padding-right: 0px;
    }

}


/* Additional Content
-------------------------------------------------- */

.additional-content,
.additional-content a {
    text-shadow: none;
}

.additional-content.horizontal-list {
    font-weight: bold;
}

.additional-content.h4 {
    line-height: 22px;
}

.additional-content.h5 {
    line-height: 18px;
}

.additional-content.h6 {
    line-height: 16px;
}

.additional-content ul {
    padding-left: 20px;
}

.additional-content li {
    padding: 0px 0px 5px 0px;
}

/* Cards - Panel */
.card-panel {
    width: 100%;
    border-color: rgba(0,0,0,.1);
}

.card-panel .card {
    margin-bottom: 0;
}

.card .card-social {
    float: right;
    margin-left: 15px;
}

.card .social-share-links>ul {
    margin-bottom: 0;
}

.card .social-share-links.list-inline>li {
    padding: 0;
}

/* Cards - Grid */
.card-grid .card .card-image .photo {
    width: 100%;
    margin: 0;
}

/* Remove panel padding to accomdate grid display options */
.card-grid.card-bg-color .panel-body {
    padding: 0;
}

/* Overwrite summary card margins and padding for grid display */
.card-grid .card-body,
.card-grid .card.has-image > .card-container > .card-body,
.card-grid.card-img-lg .card.has-image > .card-container > .card-body {
    margin-left: 0;
    padding: 0;
}

/* Add body padding for grid with background color display */
.card-grid.card-bg-color .card-body,
.card-grid.card-bg-color .card.has-image > .card-container > .card-body,
.card-grid.card-img-lg .card.has-image > .card-container > .card-body {
    padding: 15px;
}

/* Remove top body padding when img top */
.card-grid.card-bg-color .image-top .card-body, 
.card-grid .card.has-image.image-top > .card-container > .card-body, 
.card-grid.card-img-lg .card.has-image.image-top > .card-container > .card-body {
    padding-top: 0px;
}

/* Add bottom margin to top and middle image display */
.card-grid .card-container .card-image.card-image-top,
.card-grid .card-container .card-image.card-image-middle {
    margin-bottom: 15px;
}

/* Pad img for medium display */
.card-grid.card-img-md .card-panel .card-image {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

/* Cards - Audio
-------------------------------------------------- */

.card .card-body audio::-webkit-media-controls-enclosure {
    max-width: 100%;
}

.card.audio .card-image {
  width: 100%;
  padding-bottom:10px;
}

.card.audio .card-body {
    overflow: hidden;
}

.card.audio audio {
    max-width: 100%;
}

.squery-md .card.audio .card-image {
    float: left;
    width: 33%;
    padding-bottom: 0px;
}

.squery-md .card.audio.has-image .card-body {
    margin-left: 33%;
    padding-left: 15px;
}

.inline-audio .card-headline h3 {
    font-size: 18px;
}

/* Cards - Text promo */
/* TODO - Revist. This is a patch to correct a change Bootstrap made to the media object. */
.card.promo figure.pull-left img.media-object.img-thumbnail {
    max-width: 100%;
}

/* Cards - Coupon
-------------------------------------------------- */
.card.coupon {
    padding: 15px;
    background: #fff;
    border: 2px dashed rgba(0,0,0,0.1);
}

.light .card.coupon {
    background: #333;
    border: 2px dashed rgba(255,255,255,0.2);
}

.card.coupon .card-body figure{
    margin: 0;
}

.card.coupon .card-image-full .photo,
.card.coupon .card-image-full .photo img{
    width: 100%
}

.card.coupon .card-image-full .photo,
.card.coupon .alert{
    margin-top: 0px;
    margin-bottom: 10px;
}
.card.coupon .card-image-coupon{
    width: 33%;
    margin-right: 10px;
    margin-bottom: 10px;
}
.card.coupon .save-asset.btn{
    width: 100%;
}

.card.coupon .card-business,
.card.coupon .card-disclaimer,
.card.coupon .card-meta {
    clear: both;
    margin-bottom: 10px;
}

.card.coupon .save-asset{
    margin-bottom: 10px;
}

.card.coupon.panel {
    box-shadow: none;
    border: 2px dashed rgba(0,0,0,.08);
}

/* Cards - Coupon (related side bar)
-------------------------------------------------- */

.asset-related-coupon.inline-asset .card-business .card-image {
    width: 100% !important;
}

.asset-related-coupon.inline-asset .media-preview.business-asset {
    width: 50%;
    margin: 0px auto;
}

.asset-related-coupon.inline-asset .card-business .card-body {
    margin-left: 0 !important;
    padding-left: 10px !important;
}

.asset-related-coupon.inline-asset .panel-body {
    padding: 0px;
}

.asset-related-coupon.inline-asset .card.business .card-image img {
    margin: auto;
    width: auto;
}

.asset-related-coupon.inline-asset .card.business {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
}

.asset-related-coupon .card.coupon .save-asset {
    margin: 5px 0 10px 0;
}

/* Cards - Coupon Premium
-------------------------------------------------- */
.card.coupon-premium {
    padding: 15px;
    background: #fff;
    border: 2px dashed rgba(0,0,0,0.2);
}

.light .card.coupon-premium {
    background: #333;
    border: 2px dashed rgba(255,255,255,0.2);
    
}

.card-panel .card.coupon-premium {
    border-bottom: 2px dashed rgba(0,0,0,0.2);
}

.light .card-panel .card.coupon-premium {
    border-bottom: 2px dashed rgba(255,255,255,0.2);
}

.card.coupon-premium .card-image-full .photo,
.card.coupon-premium .alert {
    margin-top: 0px;
    margin-bottom: 10px;
}

.card.coupon-premium .alert {
    padding: 10px;
    font-size: 16px;
}

.card.coupon-premium .card-image-coupon {
    margin-bottom: 10px;
}

.card.coupon-premium .card-social {
    float: none;
    margin-left: 0;
}

.card.coupon-premium .card-biz-logo {
    padding-top: 10px;
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 10px;
}

.light .card.coupon-premium .card-biz-logo {
    border-top: 1px solid rgba(255,255,255,0.2);
}

.card.coupon-premium .card-biz-logo .card-image {
    max-width: 100px;
    margin: auto;
}

.card.coupon-premium .badge{
    display: none;
}

.card.coupon-premium {
    text-align: center;
}

.card.coupon-premium .social-share-link.fb .tnt-stack {
    background-color: #6378af;
    color: white;
}

.card.coupon-premium .social-share-link.tw .tnt-stack {
    background-color: #77bbf0;
    color: white;
}

.card.coupon-premium .social-share-link.em .tnt-stack,
.card.coupon-premium .social-share-link.pn .tnt-stack {
    background-color: #585858;
    color: white;
}

.card.coupon-premium .social-share-link.pr .tnt-stack {
    background-color: #738a8d;
    color: white;
}

.card.coupon-premium .card-image-coupon {
    max-width:50%; 
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
}

.card.coupon-premium .card-date.expiration {
    padding-bottom: 10px;
}

.card.coupon-premium .card-business-name h5 {
    font-weight: 300;
}

.card.coupon-premium .card-lead,
.card.coupon-premium .card-biz-logo,
.card.coupon-premium .alert {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.card.coupon-premium .card-lead p  {
    line-height: 20px;
    font-size: 14px;
    padding-top: 10px;
}

.card.coupon-premium .card-meta {
    padding-bottom: 30px;
}

.card.coupon-premium .card-disclaimer {
    padding-bottom: 10px;
}

.card.coupon-premium .card-social {
    margin-bottom: 20px;
}

.card.coupon-premium .alert {
    margin-bottom: 20px;
}

#classifieds-results-container .card.coupon-premium,
.asset-body .card.coupon-premium  {
    background-color: #fff;
    border: 2px solid rgba(0,0,0,0.2);
    box-shadow: 2px 2px rgba(0,0,0,.1);
}

#classifieds-results-container .card.coupon-premium .card-container,
.asset-body .card.coupon-premium .card-container {
    background-color: #fff;
    border: 2px dashed rgba(0,0,0,0.2);
    padding: 10px;
}

/* Cards - Product
-------------------------------------------------- */

.card.product .card-image-group li {
    width: 25%;
    float: left;
}

.card.product .job-info {
    margin-bottom: 10px;
}


/* Cards - Product (related side bar)
-------------------------------------------------- */

.asset-related-product.inline-asset .card-meta {
    text-align: center;
}

.asset-related-product.inline-asset .card-price {
    font-weight: bold;
    font-size: 158%;
    line-height: 25px;
}

.asset-related-product.inline-asset .card-business .card-image {
    width: 100% !important;
}

.asset-related-product.inline-asset  .media-preview.business-asset {
    width: 50%;
    margin: 0px auto;
}

.asset-related-product.inline-asset .card-business .card-body {
    margin-left: 0 !important;
    padding-left: 10px !important;
}

.asset-related-product.inline-asset .card.business .card-image img {
    margin: auto;
    width: auto;
}

.asset-related-product.inline-asset .card.business {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
}

.view-button {
    font-weight: bold;
    padding: 14px 7px 14px 7px;
    font-size: 14px;
}


/* Cards - Inline
-------------------------------------------------- */

.inline-asset .card {
    border: 1px solid rgba(0,0,0,.1);
    margin: 0;
}

.inline-asset .card .card-image figure {
    margin: 0;
}

.inline-collection .collection-gallery-content {
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-top: 20px;
    padding-bottom: 20px;
}

.inline-asset .card.photo-carousel,
.inline-asset .card.mosaic-item {
    border: none;
}

.inline-asset .card .card-body {
    padding: 10px;
}

.inline-asset .card.summary .card-image .photo {
    width: auto;
    float: none;
}

.inline-asset .card .card-body .card-headline h3 {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
}

.inline-asset .card .card-body .card-lead {
    display: none;
}

.inline-asset .card .card-body .card-lead p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
}

.dark-theme .card-panel {
    border-color: rgba(255,255,255,.1);
}

.popover-content .card:last-child {
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {

    .card .card-container > .card-image {
        width: 33%;
        float: left;
    }

    .card.image-right .card-container > .card-image {
        float: right;
        margin-right: 0px;
        margin-left: 15px;
    }

    .card-img-sm .card .card-container > .card-image {
        width: 15%;
    }

    .card-img-lg .card .card-container > .card-image {
        width: 40%;
        max-height: none;
    }
    
    .card-img-xlg .card .card-container > .card-image {
        width: 60%;
        max-height: none;
    }

    .card.has-image > .card-container > .card-body {
        margin-left: 33%;
        padding: 0 0 0 15px;
    }

    .card.has-image.image-right > .card-container > .card-body  {
        margin-left: 0;
        margin-right: 33%;
        padding: 0 15px 0 0;
    }

    .card-img-sm .card.has-image > .card-container > .card-body {
        margin-left: 15%;
    }

    .card-img-sm .card.has-image.image-right > .card-container > .card-body {
        margin-left: 0;
        margin-right: 15%;
    }

    .card-img-lg .card.has-image > .card-container > .card-body {
        margin-left: 40%;
    }
    
    .card-img-xlg .card.has-image > .card-container > .card-body {
        margin-left: 60%;
    }

    .card-img-lg .card.has-image.image-right > .card-container > .card-body {
        margin-left: 0;
        margin-right: 40%;
    }
    
    .card-img-xlg .card.has-image.image-right > .card-container > .card-body {
        margin-left: 0;
        margin-right: 60%;
    }

    .card .card-labels {
        display: block;
    }

    .card .card-meta .cm {
        display: block;
    }

    .card-grid .card .card-container > .card-image {
        width: 100%;
        float: none;
    }
    
    .card-grid .card.image-right .card-container > .card-image,
    .card-grid .card.image-left .card-container > .card-image {
        width: 33%;
        float: right;
        margin-right: 0px;
        margin-left: 15px;
    }
    
    .card-grid .card.image-left .card-container > .card-image {
        float: left;
        margin-right: 15px;
        margin-left: 0px;
    }

    .card-grid.card-img-sm .card-panel .panel-body {
        padding: 15px;
    }

    .card-grid.card-img-sm .card-panel .card-body {
        padding: 0px;
    }

    .card-grid.card-img-sm .card.has-image.image-top > .card-container > .card-image {
        width: 20%;
        float: right;
    }

    .card-grid.card-img-sm .card.has-image.image-top > .card-container > .card-body {
        margin: 0 20% 0 0;
        padding: 0 15px 0 0;
    }
    
    .card-grid.card-img-sm .card-panel .card.has-image.image-top > .card-container > .card-body {
        padding: 0 15px 0 0;
    }

    .inline-asset .card .card-container > .card-image {
        width: 100%;
        float: none;
    }

    .inline-asset .card.has-image > .card-container > .card-body {
        margin: 0;
        padding: 10px;
    }

    .inline-asset .card .card-body .card-lead {
        display: block;
    }

}

.actions.pull-right {
    margin: 0 10px;
}

@media (min-width: 992px) {}

.light .asset .asset-body p,
.light div.caption-inner div.caption-text p,
.light .asset .photo-carousel .caption-container p {
    color: rgba(255,255,255,.8);
}

.block.dark .asset .asset-body p,
.block.dark div.caption-inner div.caption-text p,
.block.dark .asset .photo-carousel .caption-container p,
.card.dark .asset .asset-body p,
.card.dark div.caption-inner div.caption-text p {
    color: rgba(0, 0, 0, 0.8);
}

.block.light .actions a.buy-now,
.block.dark .actions a.buy-now,
.card.light .actions a.buy-now,
.card.dark .actions a.buy-now { 
    color: #fff
}


/* Calendar card: Event block
-------------------------------------------------- */

.card.event .card-container > .card-image {
    width: 33%;
    float: left;
}

.card-img-sm .card.event .card-container > .card-image {
    width: 15%;
}

.card-img-lg .card.event .card-container > .card-image {
    width: 40%;
    max-height: none;
}

.card-img-xlg .card.event .card-container > .card-image {
    width: 60%;
    max-height: none;
}

.card.event.has-image > .card-container > .card-body {
    margin-left: 33%;
    padding: 0 0 0 15px;
}

.card-img-sm .card.event.has-image > .card-container > .card-body {
    margin-left: 15%;
}

.card-img-lg .card.event.has-image > .card-container > .card-body {
    margin-left: 40%;
}

.card-img-xlg .card.event.has-image > .card-container > .card-body {
    margin-left: 60%;
}

.card.event .card-labels {
    display: block;
}

.card.event .card-meta .cm {
    display: block;
}

.card-grid .card.event .card-container > .card-image {
    width: 100%;
    float: none;
}

.card-grid.card-img-sm .card.event.has-image > .card-container > .card-image {
    width: 20%;
    float: right;
}

.card-grid.card-img-sm .card.event.has-image > .card-container > .card-body {
    margin: 0 20% 0 0;
    padding: 0 15px 0 0;
}

.card-grid .card.event.has-image > .card-container > .card-body {
    margin-left: 0;
    padding: 15px 0 0 0;
}

.card-grid .card-panel .card.event.has-image > .card-container > .card-body {
    padding: 15px;
}

.card-grid.card-img-sm .card-panel .card.event.has-image > .card-container > .card-body {
    padding: 0 15px 0 0;
}

.inline-asset .card.event .card-container > .card-image {
    width: 100%;
    float: none;
}

.inline-asset .card.event.has-image > .card-container > .card-body {
    margin: 0;
    padding: 10px;
}

.inline-asset .card.event .card-body .card-lead {
    display: block;
}

.date-box .card.event-list {
    float: left;
    width: 50px;
    margin: 0 10px 0 0;
    height: 60px;
}


/* Calendar Event List
-------------------------------------------------- */

.card.event-list {
    list-style: none;
    padding: 0;
}

.card.event-list .event-list-item {
    width: 100%;
    margin-bottom: 15px;
}

.card.event-list .event-list-item.last {
    margin-bottom: 0px;
}

.card.event-list .event-list-item .event-date-container {
    float: left;
    width: 50px;
    text-align: center;
}

.card.event-list .event-list-item .event-info {
    overflow: hidden;
    margin-left: 50px;
}

.card.event-list .event-list-item .event-info .tnt-svg {
    width: 15px;
    text-align: center;
    margin-right: 2px;
}

.card.event-list .event-list-item .event-date-container span.event-day {
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
}

.card.event-list .event-list-item .event-date-container span.event-month {
    display: block;
    text-transform: uppercase;
    line-height: 1;
}

.card.event-list .event-list-item .event-date-container span.event-past {
    display: block;
    font-size: 24px;
    line-height: 1;
}

.card.event-list .event-title h2,
.card.event-list .event-title h3,
.card.event-list .event-title h4 {
    margin: 0;
}

.card.event-list .event-title h2 {
    font-size: 24px;
}

.card.event-list .event-title h3 {
    font-size: 18px;
}

.card.event-list .event-title h4 {
    font-size: 16px;
}

.card.event-list .event-list-item .event-date p {
    margin: 0;
}


/* Business Cards
-------------------------------------------------- */

.card.business .card-tag {
    position: absolute;
    top: 0;
    right: 0;
}

.card.business .card-tag .tag-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 50px 50px 0;
}

.card.business .card-tag .tag-txt {
    padding: 6px 9px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    color: #fff;
}

.card.business .card-social {
    float:none;
    margin-left:0;
    margin-bottom: 10px;
}

.card.business .social-share-links.list-inline>li {
    padding:0 5px 0 0;
}

.card.business.featured .card-tag .tag-bg {
    border-color: transparent #ccc transparent transparent;
}

.card.business.power .card-tag .tag-bg {
    border-color: transparent #77bf3b transparent transparent;
}

.card.business.enhanced .card-tag .tag-bg {
    border-color: transparent #589bf7 transparent transparent;
}

/* Compact Mode - Not mobile first :_( */
@media (max-width: 767px) {

    .card-compact > .card-panel,
    .card-compact.card-panel,
    .card-compact > .card,
    .card-compact > .row > div > .card-panel,
    .card-compact > .row > div > .card {
        width: auto;
        margin: 0;
        box-shadow: none;
        border: 1px solid rgba(0,0,0,.1);
        border-width: 0 0 1px 0;
    }
        
    .card-compact > .card, .card-compact > .row > div > .card {
        padding: 15px;
    }
    
    .card-compact > .tnt-load-more {
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .card-compact > .card:first-child {
        border-top: 1px solid rgba(0,0,0,.1);
    }

    .card-compact .card.has-image > .card-container > .card-image,
    .card-compact .card.has-image > .card-container > .card-body > .card-image {
        width: 75px;
        float: left;
        margin-right: 10px;
    }
    
    .card-compact .card.has-image.image-middle > .card-container,
    .card-compact .card.has-image.image-bottom > .card-container {
        position: relative;
    }
    
    .card-compact .card.has-image.image-middle > .card-container .card-image,
    .card-compact .card.has-image.image-bottom > .card-container .card-image {
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .card-compact .card.has-image > .card-container > .card-body {
        padding-top: 0;
    }

    .card-compact .card.has-image > .card-container > .card-body,
    .card-grid.card-compact.card-img-lg .card.has-image > .card-container > .card-body {
        margin-left: 85px;
    }

    .card-compact .card .card-headline h1,
    .card-compact .card .card-headline h2,
    .card-compact .card .card-headline h3,
    .card-compact .card .card-headline h4,
    .card-compact .card .card-headline h5,
    .card-compact .card .card-headline h6 {
        margin-bottom: 5px;
    }

    .card-compact .card .card-meta,
    .card-compact .card .card-lead,
    .card-compact .card .card-share,
    .card-compact .card .card.author {
        margin: 5px 0 0 0;
    }

    .card-compact .card .card-lead p {
        margin-bottom: 0;
    }

    .card-compact .card .card-meta ul {
        margin-bottom: 0;
        margin-left: 0;
    }

    .card-compact .card .card-meta ul li:first-child {
        padding-left: 0;
    }

    .card-compact.card-grid.card-panel .panel-body,
    .card-compact.card-img-lg .card-panel .panel-body,
    .card-compact.card-img-xlg .card-panel .panel-body,
    .card-compact .card-panel .panel-body {
        padding: 15px;
    }
    
    .card-compact.card-grid.card-panel .panel-body .card-body,
    .card-compact.card-grid.card-panel.card-img-md .card-image,
    .card-compact.card-img-lg .card-panel .card-body,
    .card-compact.card-img-xlg .card-panel .card-body,
    .card-compact > .row > div > .card-panel .card-body {
        padding: 0;
    }
    
    /** Remove padding for Compact Card Grid */
    .card-grid.card-compact.card-img-md .card-panel .card-image,
    .card-grid.card-compact.card-bg-color .card-body, 
    .card-grid.card-compact.card-bg-color .card.has-image > .card-container > .card-body, 
    .card-grid.card-compact.card-img-lg .card.has-image > .card-container > .card-body {
        padding: 0px;
    }
}


/* Icons, flags, and labels
-------------------------------------------------- */

.premium-asset-icon {
    font-size: 62%;
    vertical-align: 5px;
}

.headline-list .premium-asset-icon {
    vertical-align: 2px;
    margin-left: -18px;
    margin-right: 4px;
}

.headline-list li.hide-bullets {
    list-style-type: none;
}

.headline-list .premium-asset-icon {
    vertical-align: 2px;
    margin-left: -18px;
    margin-right: 4px;
}

.photo .stock-photo-label {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 2px 4px;
}

.stock-photo-label {
    color: rgba(255,255,255,.6);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.card figure .image .asset-icon,
.card-image-group .asset-icon,
.masonry-content .asset-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px;
    color: #FFF;
    background-color: rgba(0,0,0,0.65);
    z-index: 2;
}

.card-image-group .asset-icon {
    z-index: 10;
    margin: 0.5%;
}

.asset-icon > svg:not(:first-child) {
    margin-left: 10px;
}

/* Ads
-------------------------------------------------- */

.tnt-ads,
.tncms-region-ads > div:first-child,
.tnt-ads-container > div:first-child {
    display: block;
    margin: 0 auto 20px;
}

.tnt-ads.margin-bottom-none,
.tncms-region-ads > div.margin-bottom-none:first-child,
.tnt-ads-container > div.margin-bottom-none:first-child {
    margin: 0 auto;
}

.tnt-ads-container iframe,
.tncms-region-ads iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.tncms-region-ads:empty {
   margin-bottom: 0px;
}

iframe[id^=html5-ad-frame] {
    margin-bottom: 40px;
}

.tnt-ads-impact .tnt-ads {
    margin: 0 auto;
}

#fixed-impact-top,
#fixed-impact-bottom,
#blox-ad-position-fixed-impact-top1,
#blox-ad-position-fixed-impact-bottom1 {
    margin: 0 auto;
}

@media screen and (min-width: 768px) {

    .tnt-ads,
    .tncms-region-ads > div:first-child,
    .tnt-ads-container > div:first-child {
        margin-bottom: 40px;
    }

    .tnt-ads.margin-bottom-none,
    .tncms-region-ads > div.margin-bottom-none:first-child,
    .tnt-ads-container > div.margin-bottom-none:first-child {
        margin: 0 auto;
    }

}

@media screen and (max-width: 320px) {

    #fixed-leaderboard-top-container {
        padding: 0;
    }

    #fixed-leaderboard-bottom-mobile {
        margin: 0 0 20px -15px;
    }

}

.tnt-ads-overline,
.tncms-region-ads > div:first-child.tnt-ads-overline {
    color: #CCC;
    font-size: .625em;
    letter-spacing: 1px;
    margin: 0 0 .6em 0;
    text-align: center;
    text-transform: uppercase;
}

.tnt-ads-container.tnt-ads,
.tnt-ads-container .tnt-ads > div:first-child,
.tncms-region-ads .tnt-ads > div:first-child,
.tnt-ads-impact .tnt-ads {
    margin: 0 auto;
}

div[id*="debug"] {
    margin: 0 auto !important;
}


/* Search forms
-------------------------------------------------- */

.app-search .main-content .followed-notify-btn {
    margin-bottom: 20px;
}

.search-form-container {
    position: relative;
}

.search-form-container .centered-content {
    position: relative;
    z-index: 1;
    max-width: 100%;
}

.search-form-container .img-responsive{
    width: 100%;
}

.checkbox.search-section {
    width: 33%;
    float: left;
}

.range-search-form .input-group-btn > .btn.dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.range-search-form .form-group {
    margin-bottom: 0;
}

@media screen and (max-width: 991px) {

    .search-form-container .content-overlay {
        position: relative;
    }

    .search-form-container.has-image .centered-content-stage {
        background-repeat: no-repeat;
        background-size: cover;
    }

    .search-form-container .centered-content-stage img {
        display: none;
    }

}

@media screen and (min-width: 992px) {

    .search-form-container.has-image .centered-content {
        max-width: 90%;
    }

}

@media screen and (min-width: 1280px) {

    .container-fullscreen-region .search-form-container .centered-content {
        max-width: 65%;
    }

}

.checkbox.search-section {
    width: 33%;
    float: left;
}

@media screen and (max-width: 768px) {

    .checkbox.search-section {
        width: 50%;
    }

}

/* Search URLs
-------------------------------------------------- */
#tnt-search-url-results {
    padding-bottom: 0px;
    margin-bottom: 40px;
}

#tnt-search-url-reveal {
    position: relative;
    top: 17px;
}

/* Social share links
-------------------------------------------------- */

.asset .share-container {
    font-size: 16px;
}

@media(min-width: 768px) {
    .asset .share-container.content-above {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.asset .share-container .social-share-links {
    line-height: 1;
}

.social-share-links.stacked .social-share-link,
.block .social-share-links.square-color li {
    margin-bottom: 1px;
    max-height: 2em;
}

.social-share-link {
    opacity: 0.8;
    transition: all .1s ease-in-out;
}

.social-share-links .social-share-link:hover {
    opacity: 1;
}

.social-share-link a,
.social-share-link button {
    display: block;
    text-decoration: none;
    white-space: nowrap;
    color:#222;
}

.share-container .list-inline.social-share-links.square .social-share-link a,
.social-share-links.square-color .social-share-link a,
.share-container .list-inline.social-share-links.square .social-share-link button,
.social-share-links.square-color .social-share-link button {
    color: transparent;
}

.block.light .social-share-links.square-color .tnt-stack-2x.tnt-square-full,
.block.dark .social-share-links.square-color .tnt-stack-2x.tnt-square-full,
.block.dark .card .social-share-links.square-color a:hover, 
.block.light .card .social-share-links.square-color a:hover,
.card.light .social-share-links.square-color .tnt-stack-2x.tnt-square-full,
.card.dark .social-share-links.square-color .tnt-stack-2x.tnt-square-full,
.card.dark .social-share-links.square-color a:hover, 
.card.light .social-share-links.square-color a:hover {
    background-color: transparent;
    color: transparent;
}

.social-share-links.icon .social-share-link a,
.social-share-links.icon .social-share-link button {
    color: inherit;
}

.share-container .list-inline.social-share-links.square li {
    background-color: #222;
}

.block.light .tnt-stack-2x.tnt-square-full {
    background-color: #fff;
}

.social-share-links .social-share-link a:hover .share-expand  {
    color: #fff;
}

/* Facebook */
.social-share-links .social-share-link .fb:hover .tnt-stack + .tooltip > .tooltip-inner,
.share-container .list-inline.social-share-links.square li.fb:hover,
.social-share-links.square-color li.fb {
    background-color: #3B5998;
}

.social-share-links .social-share-link .fb:hover .tnt-stack + .tooltip > .tooltip-arrow {
    border-left-color: #3B5998;
}

/* Whats App */
.social-share-links .social-share-link .wa:hover .tnt-stack + .tooltip > .tooltip-inner,
.share-container .list-inline.social-share-links.square li.wa:hover,
.social-share-links.square-color li.wa {
    background-color: #25d366;
}

/* Twitter */
.social-share-links .social-share-link .tw:hover .tnt-stack + .tooltip > .tooltip-inner,
.share-container .list-inline.social-share-links.square li.tw:hover,
.social-share-links.square-color li.tw {
    background-color: #000;
}

.social-share-links .social-share-link .tw:hover .tnt-stack + .tooltip > .tooltip-arrow {
    border-left-color: #000;
}

/* Linked In */
.social-share-links .social-share-link .lin:hover .tnt-stack + .tooltip > .tooltip-inner,
.share-container .list-inline.social-share-links.square li.lin:hover,
.social-share-links.square-color li.lin {
    background-color: #0a66c2;
}

.social-share-links .social-share-link .lin:hover .tnt-stack + .tooltip > .tooltip-arrow {
    border-left-color: #0a66c2;
}

.share-container .list-inline.social-share-links.square li.gp:hover,
.social-share-links.square-color li.gp {
    background-color: #dd4b39;
}

.share-container .list-inline.social-share-links.square li.em:hover,
.social-share-links.square-color li.em {
    background-color: #585858;
}

.social-share-links .social-share-link .sms:hover .tnt-stack + .tooltip > .tooltip-inner,
.share-container .list-inline.social-share-links.square li.sms:hover,
.social-share-links.square-color li.sms {
    background-color: #4dc247;
}

.social-share-links .social-share-link .sms:hover .tnt-stack + .tooltip > .tooltip-arrow {
    border-left-color: #4dc247;
}

.share-container .list-inline.social-share-links.square li.pr:hover,
.social-share-links.square-color li.pr {
    background-color: #738a8d;
}

/* Copy to clipboard */
.share-container .list-inline.social-share-links.square li.copy:hover,
.social-share-links.square-color li.copy {
    background-color: #00334E;
}

.share-container .list-inline.social-share-links.square li.sv:hover,
.social-share-links.square-color li.sv {
    background-color: #cc0000;
}

.social-share-links .social-share-link:hover .fb {
    color: #3B5998;
}

.social-share-links .social-share-link:hover .tw {
    color: #999;
}

.social-share-links .social-share-link:hover .lin {
    color: #0a66c2;
}

.social-share-links .social-share-link:hover .wa {
    color: #25d366;
}

.social-share-links .social-share-link:hover .gp {
    color: #dd4b39;
}

.social-share-links .social-share-link:hover .em,
.social-share-links .social-share-link:hover .copy {
    color: #585858;
}

.social-share-links .social-share-link:hover .sms {
    color: #4dc247;
}

.social-share-links .social-share-link:hover .pr {
    color: #738a8d;
}

.social-share-links .social-share-link:hover .save-asset {
    color: #cc0000;
}

.social-share-links.expanding.icon .social-share-link:hover a {
    color: #fff;
}

.social-share-links.expanding.circle .social-share-link a {
    border-radius: 15px;
}

.asset .share-container.content-left {
    margin: 50px 0 50px 0;
    float: left;
    font-size: 26px;
}

body.fixed-nav .asset .share-container.content-left {
    margin: 100px 0 50px 0;
}

.asset .share-container.content-left.affix {
    position: fixed !important;
    top: 0;
}

#share-left-affix button.btn-link {
    border: 0;
    padding: 0;
}

.list-inline.social-links {
    margin-left: 0;
}

.list-inline.social-links > li {
    padding-left: 0;
    padding-right: 0;
}

@media screen and (min-width: 768px) {    
    .share-container .list-inline.social-share-links.square li,
    .share-container .list-inline.social-share-links.square li:hover,
    .share-container .list-inline.social-share-links.square li.fb:hover,
    .share-container .list-inline.social-share-links.square li.tw:hover,
    .share-container .list-inline.social-share-links.square li.lin:hover,
    .share-container .list-inline.social-share-links.square li.wa:hover,
    .share-container .list-inline.social-share-links.square li.gp:hover,
    .share-container .list-inline.social-share-links.square li.em:hover,
    .share-container .list-inline.social-share-links.square li.sms:hover,
    .share-container .list-inline.social-share-links.square li.pr:hover,
    .share-container .list-inline.social-share-links.square li.copy:hover,
    .share-container .list-inline.social-share-links.square li.sv:hover,
    .social-share-links.square-color li,
    .social-share-links.square-color li.fb,
    .social-share-links.square-color li.tw,
    .social-share-links.square-color li.lin,
    .social-share-links.square-color li.gp,
    .social-share-links.square-color li.em,
    .social-share-links.square-color li.sms,
    .social-share-links.square-color li.pr,
    .social-share-links.square-color li.copy,
    .social-share-links.square-color li.sv {
        background-color: transparent;
    }
    
    .tnt-stack-2x.tnt-square-full {
        background-color: #222;
    }
    
    .social-share-links .social-share-link .fb:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .fb .tnt-stack-2x.tnt-square-full {
        background-color: #3B5998;
    }
    
    .social-share-links .social-share-link .tw:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .tw .tnt-stack-2x.tnt-square-full {
        background-color: #000;
    }
    
    /* Linked In */
    .social-share-links .social-share-link .lin:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .lin .tnt-stack-2x.tnt-square-full {
        background-color: #0a66c2;
    }
    
    .social-share-links .social-share-link .wa:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .wa .tnt-stack-2x.tnt-square-full {
        background-color: #25d366;
    }
    
    .social-share-links .social-share-link .gp:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .gp .tnt-stack-2x.tnt-square-full,
    .social-share-links.expanding .social-share-link:hover .gp {
        background-color: #dd4b39;
    }
    
    .social-share-links .social-share-link .em:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .em .tnt-stack-2x.tnt-square-full,
    .social-share-links.expanding .social-share-link:hover .em {
        background-color: #585858;
    }

    .social-share-links .social-share-link .sms:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .sms .tnt-stack-2x.tnt-square-full {
        background-color: #4dc247;
    }

    .social-share-links .social-share-link .pr:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .pr .tnt-stack-2x.tnt-square-full,
    .social-share-links.expanding .social-share-link:hover .pr {
        background-color: #738a8d;
    }
    
    .social-share-links .social-share-link .copy:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .copy .tnt-stack-2x.tnt-square-full,
    .social-share-links.expanding .social-share-link:hover .copy {
        background-color: #00334E;
    }
    
    .social-share-links .social-share-link .save-asset:hover .tnt-stack-2x.tnt-square-full,
    .social-share-links.square-color .social-share-link .save-asset .tnt-stack-2x.tnt-square-full,
    .social-share-links.expanding .social-share-link:hover .save-asset {
        background-color: #cc0000;
    }
}

.social-share-link.wa {
    display: none;
}

@media (max-width: 767px){
    .social-share-link.wa {
        display: inline-block;
    }
}


/* Avatars
-------------------------------------------------- */

.tn-user-avatar svg.tnt-user {
    background: #C4C4C4;
    color: #FFF;
    font-size: 23px;
    padding: 3px 0;
    width: 30px;
}

.comment-user {
    width: 40px;
}

/* user and author */
.card.author {
    position: inherit;
}
.card.author .avatar-box {
    position: relative;
    margin-right: 10px;
    width: 100px; /* initial width */
    height: 100px;
}

.block .card.author .avatar-box {
    width: 25px;
    height: 25px;
}

.card.author .avatar-box:before {
    content: "";
    display: block;
    padding-top: 100%; /* initial ratio of 1:1*/
}

.card.author .avatar-box .tnt-user-profile-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.block .card.author .avatar-box .tnt-user-avatar {
    width: 100%;
    max-height: 25px;
    object-fit: cover;
}

.block .card .card.author,
.block .card .card.author .media-list,
.block .card .card.author .card-tagline p {
    margin-bottom: 0;
}


/* Mini Event Cards
-------------------------------------------------- */

.card.event.mini .card-date-square a,
.event-mini-weekly-date a {
    text-decoration: none;
}

.card.event.mini .card-date-square a:hover .date-square,
.event-mini-weekly-date a:hover .date-square {
    background-color: rgba(0,0,0,.05);
}

.card.event.mini .date-col {
    padding-right: 0;
}

.card.event.mini .card-headline * {
    margin-top: 0;
}

.card.event.mini .card-image .photo {
    overflow: hidden;
    max-height: 200px;
    width: 25%;
    float: left;
    margin-top: 0;
    margin-right: 15px;
    margin-bottom: 10px;
}

.card.event.mini .card-schedule {
    font-style: italic;
    color: #444;
}

.event-mini-weekly-date {}


/* Modals
-------------------------------------------------- */

.modal.fullscreen {
    background-color: rgba(0,0,0,.85);
    padding-right: 0!important;
}

.modal.fullscreen .close {
    position: absolute;
    color: #fff;
    opacity: .4;
    font-size: 30px;
    z-index: 2;
    top: 15px;
    right: 15px;
}

.modal.fullscreen .close:hover {
    opacity: .8;
}

.modal.fullscreen .modal-body {
    height: 100%;
    width: 100%;
    padding: 0;
}

.modal.fullscreen .modal-dialog {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.modal.fullscreen .modal-content {
    height: 100%;
    width: 100%;
    border-radius: 0;
    padding: 0;
    border: 0;
    background: transparent;
}

.modal.fullscreen.modal-image .modal-dialog {
    height: initial;
}

.modal.fullscreen.modal-image .close {
    position: fixed;
    right: 30px;
}

.modal.fullscreen.modal-image .modal-image-container {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.modal.fullscreen.modal-image img {
    width: 100vw;
    height: calc(100vh - 100px);
    object-fit: contain;
    font-family: 'object-fit: contain;' /* polyfilled for ie/Edge. */
}

.modal.fullscreen .photo-carousel {
    margin: 0;
    background: transparent;
    height: 100%;
}

.modal.fullscreen .photo-carousel .carousel-inner {
    width: 900px;
    margin: 0 auto;
}

.modal.fullscreen .owl-carousel,
.modal.fullscreen .owl-wrapper-outer,
.modal.fullscreen .owl-wrapper,
.modal.fullscreen .owl-item,
.modal.fullscreen .item,
.modal.fullscreen .owl-carousel .owl-stage,
.modal.fullscreen .owl-carousel .owl-stage-outer,
.modal.fullscreen .item-container {
    height: 100%!important;
    position: relative;
}

.modal.fullscreen .photo-carousel .item .photo-container {
    background: transparent;
}

.modal.fullscreen .photo-carousel .item .photo-container img,
.modal.fullscreen .photo-carousel .item .photo-container .tnt-video-container {
    max-height: 100%;
    max-width: 100%;
    height: initial;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.modal.fullscreen .photo-carousel .item .photo-container {
    height: 100%;
    padding: 3% 0;
}

.modal.fullscreen .photo-carousel .caption-container,
.modal.fullscreen.modal-image .caption-container {
    width: 100%;
    opacity: 1;
    color: #fff;
    transition: all 0.25s ease-out;
    background-color: rgba(0,0,0,.7);
    position: absolute;
    padding: 15px 15px 15px;
    bottom: 0;
}

.modal.fullscreen .photo-carousel .caption-container .social-share-links,
.modal.fullscreen.modal-image .caption-container .social-share-links {
    margin-bottom: 0px;
}

.modal.fullscreen.modal-image .caption-container {
    padding-left: 30px;
    padding-right: 30px;
}

.modal.fullscreen.modal-image .modal-caption {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.7);
}
.modal.fullscreen.modal-image .caption-container {
     max-width: 900px;
     margin: 0 auto;
     position: relative;
     background-color: transparent;
}

.asset #asset-content .modal.fullscreen .photo-carousel .caption-container p,
.modal.fullscreen .photo-carousel .caption-container p,
.modal.fullscreen.modal-image .caption-container p,
.modal.fullscreen .photo-carousel .caption-container .card-headline a,
.modal.fullscreen .photo-carousel .caption-container .card-meta a,
.modal.fullscreen .photo-carousel .caption-container .caption-text a {
    color: #eee;
}

.modal.fullscreen .photo-carousel .item:hover .caption-container,
.modal.fullscreen.modal-image .caption-container {
    opacity: 1;
}

.modal.fullscreen .photo-carousel .photo-carousel-control {
    background-color: rgba(0,0,0,.3);
    color: #fff;
    font-size: 28px;
    padding: 10px;
    opacity: 1;
    bottom: 15px;
    top: auto;
    left: auto;
}

.modal.fullscreen .photo-carousel .photo-carousel-control.left {
    right: 58px;
}

.modal.fullscreen .photo-carousel .photo-carousel-control.right {
    right: 15px;
}

.modal.fullscreen .photo-carousel .photo-count {
    bottom: 72px;
    right: 22px;
    font-weight: normal;
    color: #bbb;
}

.modal.fullscreen .photo-carousel .photo-carousel-caption-toggle,
.modal.fullscreen.modal-image .photo-carousel-caption-toggle {
    opacity: .4;
    margin-top: -10px;
    margin-right: -15px;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    outline: 0;
    cursor: pointer
}

.modal.fullscreen .photo-carousel .photo-carousel-caption-toggle .tnt-svg,
.modal.fullscreen.modal-image .photo-carousel-caption-toggle .tnt-svg {
    margin: 4px 9px 4px 0;
}

.showcase-caption-toggle,
.caption-overlay .caption-toggle {
    margin-right: .3em;
    opacity: .4;
    color: #fff !important;
    text-decoration: none !important;
    outline: 0 !important;
}

.caption-overlay .tnt-svg.caption-toggle {
    margin: 6px 12px;
}

.modal.fullscreen .photo-carousel .photo-carousel-caption-toggle:hover,
.showcase-caption-toggle:hover,
.caption-overlay .caption-toggle:hover {
    opacity: .7;
}

.modal.fullscreen .photo-carousel-control.nowapp {
    display: none;
}

@media screen and (max-width: 900px) {

    .modal.fullscreen .photo-carousel .carousel-inner,
    .modal.fullscreen .photo-carousel .item .photo-container .tnt-video-container {
        width: 100%;
        max-height: initial;
    }

    .modal.fullscreen .photo-carousel-control.nowapp.left {
        display: block;
        transform: translateY(-50%);
        top: 50%;
        bottom: auto;
        left: 0;
        right: auto;
    }

    .modal.fullscreen .photo-carousel-control.nowapp.right {
        display: block;
        transform: translateY(-50%);
        top: 50%;
        bottom: auto;
        left: auto;
        right: 0;
    }

}

@media screen and (min-width: 768px) {

    .modal.fullscreen .photo-carousel.has-ad-unit {
        padding-top: 80px;
    }

    #fixed-leaderboard-top-container.overlay-leaderboard {
        position: fixed;
        top: 10px;
        z-index: 9999;
        background: transparent!important;
    }

}


/* Thumbnail Gallery
-------------------------------------------------- */

.gallery-thumbnails .thumbnail-container {
    height: 100px;
    width: 100px;
    overflow: hidden;
}

.gallery-thumbnails .photo-container {
    margin-bottom: 10px;
    margin-right: 10px;
}

.gallery-thumbnails .thumbnail-container img {
    height: 100px;
    width: auto;
    max-width: inherit;
}

.gallery-thumbnails .layout-vertical .thumbnail-container img {
    width: 100px;
    height: auto;
}

@media screen and (max-width: 767px) {

    .gallery-thumbnails .thumbnail-container {
        height: 90px;
        width: 90px;
    }

}


/* Maps
-------------------------------------------------- */

/* map object */
.map-canvas.single-location a.static-map {
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

.map-canvas .gm-style-iw .card.summary {
    margin: 8px 0 8px 8px;
    max-width: 420px;
}

#gmap-business-map,
#gmap-classified-map,
.map-canvas-dynamic {
    margin-bottom: 20px;
}

.map-canvas-dynamic {
    height: 600px;
}

.map-canvas .leaflet-popup-content {
    margin: 15px;
}
.map-canvas .leaflet-popup-content .card {
    margin-bottom: 0;
}
.map-canvas .leaflet-popup-content p {
    margin: 15px 0 0 0;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    .map-canvas .gm-style-iw .card.summary {
        max-width: calc(100vw - 200px);
    }

    .business-search-map-container .gmap_marker .card.business {
        width: 100%;
    }

    .business-search-map-container {
        right: 15px;
    }

    .business-search-map-container .card.business .card-content > div:last-child {
        width: 90%;
    }

}

.mapped-number-badge {
    padding: 3px 8px;
    margin-top: -3px;
    font-size: 20px;
    font-weight: normal;
    border-radius: 14px;
}


/* Pricing Table
-------------------------------------------------- */

.pricing-table {
    text-align: center;
    border: 1px solid rgba(0,0,0,.15);
    margin-bottom: 18px;
}

.pricing-table .header {
     background-color: rgba(0,0,0,.8);
     color: rgba(255,255,255,.8);
     font-size: 16px;
     padding: 8px;
     margin: -1px -1px 0 -1px;
}

.pricing-table .price {
    font-size: 24px;
    background-color: rgba(0,0,0,.05);
    padding: 8px;
    border-bottom: 2px solid rgba(0,0,0,.05);
    margin-top: 1px;
}

.pricing-table .price small {
    font-size: 12px;
}

.pricing-table .html {
    padding: 10px 20px;
    height: 320px;
    overflow: auto;
    text-align: left;
}

@media screen and (max-width: 767px) {

    .pricing-table .html {
        height: auto;
    }

}

.pricing-table .description {
    padding: 10px;
    color: rgba(0,0,0,.5);
    border-bottom: 1px dotted rgba(0,0,0,.1);
}

.pricing-table .features ul {
    margin: 0;
    padding: 0;
}

.pricing-table .features ul li {
    padding: 10px;
    list-style: none;
    border-bottom: 1px dotted rgba(0,0,0,.1);
}

.pricing-table .features ul li:last-child {
    border: 0;
}

.pricing-table .purchase {
    padding: 15px;
    border-top: 1px solid rgba(0,0,0,.1);
}


/* Caption Toggle
-------------------------------------------------- */

.caption-button {
    border: none;
    background: transparent;
}


/* TNPAY Errors
-------------------------------------------------- */

.tnpay-error {
    border-radius: 0;
    box-shadow: 2px 2px rgba(0,0,0,0.1);
    background-color: #F2DEDE;
    color: #A94442;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #EBCCD1;
}

.tnpay-error ul {
    margin: 0;
}


/* Payment frame
-------------------------------------------------- */

.tnt-payment-frame {
    overflow: hidden;
    margin-bottom: 15px;
    min-height: 181px;
}


/* CAPTCHA
-------------------------------------------------- */

.tncms-captcha-wrapper {
    width: auto !important;
}

.tncms-captcha-help {
    font-size: 95%;
    color: rgba(0,0,0,0.7);
    margin-top: 5px;
}

.tncms-captcha-response {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.tncms-captcha-response:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6)
}

.tncms-captcha-response::-moz-placeholder {
    color: #777;
    opacity: 1;
}

.tncms-captcha-response:-ms-input-placeholder {
    color: #777;
}

.tncms-captcha-response::-webkit-input-placeholder {
    color: #777;
}

.biz-contact-captcha .tncms-captcha-wrapper label,
.biz-contact-captcha .tncms-recaptcha-wrapper label {
    display: none;
}

@media screen and (max-width: 380px) {
    .biz-contact-captcha #rc-imageselect,
    .biz-contact-captcha .g-recaptcha {
        transform: scale(0.85);
        -webkit-transform: scale(0.85);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}


/* Business contact form
-------------------------------------------------- */
.business-contact-container .modal-biz-contact {
    width: auto;
}

@media (min-width: 400px) {
    .business-contact-container .modal-biz-contact {
        width: 360px;
        margin: 30px auto;
    }
}

.business-contact-container .panel-heading {
    font-size: 16px;
}

.business-contact-container form textarea {
    resize: vertical;
}

.business-contact-container form div.form-group:last-child {
    margin-bottom: 0;
}


/* Zoom presentation image
-------------------------------------------------- */

.zoom-overlay {
    display: block;
}
.zoom-container.clearfix i {
    left: 45%;
    position: absolute;
    color: white;
    top: 2.7%;
    font-size: 9.2em;
}

.zoom-photo-wrapper {
    position: relative;
}

.zoom-container .tnt-search-plus {
    background-position: center center;
    color: white;
    font-size: 6em;
    margin: 0 auto;
    opacity: 0.5;
    position: absolute;
    text-align: center !important;
    top: 40%;
    width: 100%;
}


/* Asset reccomendations
-------------------------------------------------- */

.asset-recommend.outbrain {
    margin-bottom: 20px;
}


/* Recent Comments
-------------------------------------------------- */

.comment-body.media-body {
    word-break: break-word;
}

.asset-comments {
    margin-bottom: 20px;
}

.recent-comments.bullet-decimal {
    list-style-type: inherit !important;
}

.commentContent {
    font-style: italic;
}

#comments-collapsible {
    opacity: 1;
    height: initial;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

#comments-collapsible.collapsed {
    opacity: 0;
    height: 0px;
}

#comments-collapsible-toggle {
    height: initial;
    display: block;
    width: 60%;
    margin: 0 auto;
}

#comments-collapsible-toggle.collapsed {
    display: none;
}


/* Asset Prev/Next Links
-------------------------------------------------- */

.asset-paging .animate {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.asset-paging a.overlay {
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    position: absolute;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 85%, rgba(255,255,255,1) 100%);
}

.asset-paging .inner {
    position: relative;
    overflow: hidden;
    max-height: 140px;
}

.asset-paging .dismiss {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    display: none;
}

.asset-paging .media p {
    font-size: 13px;
}

body.trigger-next .asset-paging .next .dismiss {
    display: block;
}

/** desktop */
@media screen and (min-width: 992px) {

    /* paging side links */
    .asset-paging .paging-link {
        position: fixed;
        top: 50%;
        top: calc(50% - 30px);
        display: none;
        outline: none;
        text-align: left;
        z-index: 1000;
        -webkit-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }

    .asset-paging .paging-link.prev {
        left: 0;
    }

    .asset-paging .paging-link.next {
        right: 0;
    }

    .asset-paging .paging-link.prev.offset {
        left: -70px;
    }

    .asset-paging .paging-link.next.offset {
        right: -70px;
    }

    .asset-paging a.arrow {
        position: relative;
        display: block;
        padding: 10px;
        width: 55px;
        color: #999;
        text-decoration: none;
        background-color: rgba(255, 255, 255, 0.5);
        border: 1px solid #ccc;
        z-index: 100;
        border: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    .asset-paging a.arrow:hover {
        background-color: #fff;
    }

    .asset-paging .arrow .tnt-svg {
        font-size: 40px;
        margin: 0;
        display: inline-block;
    }

    .asset-paging .prev a.arrow {
        border-left: 0;
    }

    .asset-paging .next a.arrow {
        border-right: 0;
    }

    .asset-paging a.arrow:hover {
        color: #333;
    }

    .asset-paging .paging-link:hover a.arrow,
    body.trigger-next .asset-paging .paging-link.next a.arrow {
        border-color: transparent;
        background: transparent;
    }

    .asset-paging .paging-link:hover .direction,
    body.trigger-next .asset-paging .paging-link.next .direction {
        opacity: 0;
    }

    .asset-paging .direction {
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        opacity: 1;
        color: #222;
    }

    /* paging flyout container */
    .asset-paging .item {
        position: absolute;
        top: 0;
        margin: 0;
        width: 400px;
        background: #fff;
        border: 1px solid #ddd;
        top: -65%;
        height: 148px;
        opacity: 0;
        -webkit-box-shadow: 0px 0px 26px -1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 26px -1px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 26px -1px rgba(0, 0, 0, 0.1);
    }

    .asset-paging .next .item {
        right: -460px;
        border-right: 0;
    }

    .asset-paging .prev .item {
        left: -460px;
        border-left: 0;
    }

    .asset-paging .paging-link.prev:hover .item {
        left: 0px;
        opacity: 1;
    }

    .asset-paging .paging-link.next:hover .item,
    body.trigger-next .asset-paging .paging-link.next .item {
        right: 0px;
        opacity: 1;
    }

    /* paging content */
    .asset-paging .dismiss {
        top: -24px;
        left: -26px;
        font-size: 24px;
    }

    .asset-paging .head {
        padding: 5px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: bold;
    }

    .asset-paging .media {
        padding: 15px;
        margin: 0;
    }

    .asset-paging .prev .item .media {
        padding-left: 50px;
    }

    .asset-paging .next .item .media {
        padding-right: 50px;
    }

    .asset-paging .media-object {
        max-width: 80px;
    }

    .asset-paging .media-heading {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 5px;
    }

    /* fluid container fixes */
    body.fluid .asset-paging {
        margin: -20px -15px 18px -15px;
        overflow: hidden;
    }

    body.fluid .asset-paging .paging-link {
        position: relative;
        top: 0;
        z-index: 1;
    }

    body.fluid .asset-paging .paging-link.prev {
        float: left;
    }

    body.fluid .asset-paging .paging-link.next {
        float: right;
    }
    
    /* fluid with cover art */
    body.fluid .has-cover-art .asset-paging {
        position: relative;
        z-index: 2;
        margin: 0px;
    }

    body.fluid .has-cover-art .asset-paging .paging-link.prev,
    body.fluid .has-cover-art .asset-paging .paging-link.next {
        position: absolute;
        top: 275px;
    }
    
    body.fluid .has-cover-art .asset-paging .paging-link.prev {
        left: -15px;
    }
    
    body.fluid .has-cover-art .asset-paging .paging-link.next {
        right: -15px;
    }
}

/* various large viewports/condensed */
body.trigger-condensed .asset-paging .direction {
    display: none;
}

body.trigger-condensed .asset-paging .arrow .tnt-svg {
    font-size: 30px; 
}

body.trigger-condensed .asset-paging a.arrow {
    padding: 25px 10px;
    width: 30px;
}

/* mobile */
@media screen and (max-width: 991px) {

    /* add a height to container to prevent jumping. */
    .asset-paging-container {
        height: 67px;
        margin: 0 -15px 40px -15px;
        overflow: hidden;
    }

    .asset-paging {
        bottom: -150px;
        -webkit-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }

    body.trigger-next .asset-paging {
        position: fixed;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        background: #fff;
        left: 0;
        margin: 0;
        -webkit-box-shadow: 0px 0px 26px -1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 26px -1px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 26px -1px rgba(0, 0, 0, 0.1);
    }

    .has-cover-art .asset-paging-container {
        visibility: hidden;
        position: fixed;
        bottom: -150px;
        z-index: 1000;
    }

    body.trigger-next .has-cover-art .asset-paging-container {
        visibility: visible;
    }

    .asset-paging .paging-link {
        display: none;
        border: 1px solid rgba(0,0,0,.1);
    }

    .asset-paging .arrow {
        width: auto;
        padding-top: 18px;
        display: block;
        height: 65px;
    }

    .asset-paging .paging-link .arrow .tnt-svg {
        font-size: 28px;
        color: #222;
    }

    .asset-paging .paging-link.prev {
        float: left;
        width: 15%;
        border-right: 1px solid rgba(0,0,0,.2);
        border-left: 0;
        background-color: rgba(0,0,0,.02);
    }

    .asset-paging .paging-link.prev .item {
        display: none;
    }

    .asset-paging .paging-link.next {
        float: right;
        width: 85%;
        border-right:0;
    }

    .asset-paging .paging-link.next .arrow {
        float: right;
        padding-right: 15px;
    }

    .asset-paging .inner {
        height: 65px;
        padding: 3px 10px;
        overflow: hidden;
    }

    .asset-paging .dismiss {
        top: -34px;
        right: -10px;
        font-size: 30px;
    }

    .asset-paging .head {
        font-size: 12px;
    }

    .asset-paging .media {
        margin-top: 4px;
    }

    .asset-paging .media-heading {
        font-size: 14px;
        line-height: 16px;
        margin: 0;
    }

    .asset-paging .media-object {
        max-width: 32px;
    }

}


/* Responsive logo
-------------------------------------------------- */

.site-logo-container a img.img-responsive {
    display: inherit;
}


/* print styles
-------------------------------------------------- */

@media print{

    a[href]::after {
        content:"";
    }

    a[href].print-url::after {
        content:" (" attr(href) ") ";
    }

    article[data-url]::before {
        content: attr(data-url);
        display: block;
        margin: 0 0 10px;
    }
    
    #asset-photo-carousel,
    #carousel-featured-images {
        margin-bottom: 20px;
    }
    
    #asset-photo-carousel .owl-carousel.carousel-inner {
        margin: auto;
        width: 100%;
        overflow: visible;
    }

    #asset-photo-carousel .photo-carousel-control-container,
    #asset-photo-carousel .owl-carousel .owl-item .item {
    	display: none;
    }
    
    #asset-photo-carousel .owl-carousel .owl-item.active .item {
    	display: block;
    }

    .asset-related-mugshot .photo.layout-vertical {
        max-width: 225px;
        height: auto;
    }

    .paginated-asset .owl-item {
        float: none !important; 
    }
    .blox-asset .tncms-region,
    .app-adowl .tncms-region {
    	display: none;
    }

    /** Cover Art CSS **/

    .asset .asset-masthead.tnt-cover-art #covert-art-arrow,
    .asset .asset-masthead.tnt-cover-art>img {
        display: none;
    }
    
    .asset .asset-masthead.tnt-cover-art .asset-header {
        max-width: 100%;
        padding: 0px;
        margin: 0;
        text-align: left;
    }
    
    .asset .asset-masthead.tnt-cover-art .asset-header h1 {
        font-size: 24px;
    }
    
    .asset .asset-masthead.tnt-cover-art {
        margin: 0px;
        height: 100px !important;
        display: block;
    }
    
    .asset .asset-masthead.tnt-cover-art .asset-header .meta {
        margin-top: 20px;
    }

}


/* Status pages
-------------------------------------------------- */

#status-404 .error-code,
#status-410 .error-code {
    font-size: 132px;
    text-align: center;
}


/* Business products
-------------------------------------------------- */

.blox-products .item { 
    margin: 5px;
}

@media (min-width:768px){
    
    .blox-products .product-items > .item { 
        width: 33%; 
        float: left; 
        margin: 0; 
        padding: 5px;
     }

    .blox-products .product-items .card .card-image img { 
        max-height: 150px;
        max-width:100%; 
        margin: 0 auto; 
    }

}

.blox-products .product-items .card .card-container > .card-image { 
    width: 100%; 
    float: none; 
}

.blox-products .product-items .card.has-image > .card-container > .card-body { 
    margin-left: 0; 
    padding-left: 0; 
}

.blox-products .product-items .card .card-image img{ 
    height:auto;
}

.blox-products .product-items .card .card-image .photo.layout-vertical { 
    background: none;
}


/* PDF extracted text
-------------------------------------------------- */

#pdf-extracted-text-container a {
    color: #333;
    cursor: pointer;
    text-decoration: none;
}

#pdf-extracted-text-container a:hover {
    text-decoration: none;
}

#pdf-extracted-text-container pre {
    margin-bottom: 0;
    background-color: inherit;
    border: none;
}


/* CE Source Text
-------------------------------------------------- */

.exchange-source {
    font-size: 13px !important;
    font-style: italic;
    color: #777 !important;
}


/* Utility: message
-------------------------------------------------- */

.message-block h3 {
    font-size: 30px; 
    font-weight: bold;
}

.message-block h2 {
    font-weight: bold;
    font-size: 40px;
}

.message-block.double-padding-top { 
    padding-top: 20px; 
}

@media (max-width: 480px){

    .message-block h3 {
        font-size: 18px; 
    }

    .message-block h2 {
        font-size: 20px;
    }

}


/* Calendar: Search
-------------------------------------------------- */

.calendar-search.search-form-container {
    background-color: inherit;
}

.calendar-search.squery-sm legend {
    display: none;
}

.calendar-search.squery-sm .col-md-12,
.calendar-search.squery-sm .col-md-6,
.calendar-search.squery-sm .col-md-4,
.calendar-search.squery-sm .col-md-3 {
    width: 100%;
}


/* Reactions
-------------------------------------------------- */

.love-icon,
input#love {
    background: url("../../../template/resources/images/love-face.b40bac7939cc4435948af192bcfb843f.png")no-repeat;
}

.funny-icon,
input#funny {
    background: url("../../../template/resources/images/haha.e8ea173c2dec30a8a10047ab33a1a984.png")no-repeat;
}

.wow-icon,
input#wow {
    background: url("../../../template/resources/images/wow.5085918dbcfaa3efb3a2f95eb5767774.png")no-repeat;
}

.sad-icon,
input#sad {
    background: url("../../../template/resources/images/sad.c8a3ceede8a7d2fafe5574387a28eb2e.png")no-repeat;
}

.angry-icon,
input#angry {
    background: url("../../../template/resources/images/angry.00f8b278199f6f72ce39eaffb33230e0.png")no-repeat;    
}

#reaction-form {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(5,1fr);
    border: 1px solid rgba(0,0,0,.08);
}

#reaction-wrap {
	margin-bottom: 20px;
}	

.form-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 7px;
    border-right: 1px solid rgba(0,0,0,.08);
    -ms-grid-row: 1; 
    -ms-grid-column-span: 1;
    grid-row: 1; 
    grid-column-span: 1;
}

.form-icons.love {
   -ms-grid-column: 1; 
   grid-column: 1;
}

.form-icons.funny {
   -ms-grid-column: 2;
   grid-column: 2;   
}

.form-icons.wow {
   -ms-grid-column: 3; 
   grid-column: 3;
}

.form-icons.sad {
   -ms-grid-column: 4;
   grid-column: 4;
}

.static-height .form-icons {
    border-top: 1px solid rgba(0,0,0,.08);
    grid-row: 2;
    -ms-grid-row: 2;
}

.static-height .form-icons.love {
    border-left: 1px solid rgba(0,0,0,.08);
}

.static-height .form-icons.angry {
    border-right: 1px solid rgba(0,0,0,.08);
}

.form-icons.angry {
    border-right: none;
    -ms-grid-column: 5; 
    grid-column: 5; 
}

.reaction-name {
    display: block !important;
    background-size: cover;
    width: 25px;
    height: 25px;
    justify-self: center;
    margin-top: 5px;
    margin-right: 5px;
}

#reaction-form div.form-icons input {
    opacity: 0;
}
    
#reaction-form:hover,
.form-icons:hover,
.reaction-name:hover {
    cursor: pointer;
}

.reacted-background,
.form-icons:hover {
    background-color:rgba(0,0,0,.1);
}

.emotion-name {
    display: none;
}
    
.form-icons:hover .reaction-name > .emotion-name {
    display: flex;
    display: -ms-flexbox;
    position: absolute;
    background: #a1a1a1;
    padding: 4px;
    color: #fff;
    width: 80px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    height: 30px;
    z-index: 999;
    align-items: center;
    justify-content: center;
    margin: -52px auto;
    margin-left: -20px;
    z-index: 9999;
}
    
.emotion-name:after {
    content: "";
    position: absolute;
    margin-left: 6px;
    border-width: 12px 12px 0 12px;
    border-style: solid;
    border-color: #a1a1a1 transparent;
    display: block;
    width: 0;
    bottom: -7px;
    left: 3%;
}

#reaction-form-message {
    height: 51px;
    margin-bottom: 20px;
    background-color: #ccc;
    text-align: center;
    color: white;
    margin-top: -51px;
    padding-top: 13px;
    border: 1px solid #cccccc;
    position: relative;
}

#reaction-form-message p {
    color: white;
    margin-top: -4px;
    font-weight: bold;
}

#reaction-form-wrapper:hover .logged-in-reminder,
#star-ratings-form-wrapper:hover .logged-in-reminder {
    opacity: 1;    
}

#reaction-form-wrapper .logged-in-reminder,
#star-ratings-form-wrapper .logged-in-reminder {
    height: 45px;
    opacity: 0;
    display: grid;
    display: -ms-flexbox;
    text-align: center;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-row: 1 / span 1;
    grid-column-end: 4;
    grid-column: 1 / span 5;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    position: relative;
}

.static-height #reaction-form,
.static-height #star-ratings-form {
    border-top: none;
    border-left: none;
    border-right: none;
}

@media only screen and (max-width : 768px) {

    .emotion-name:after,
    .form-icons:hover .reaction-name > .emotion-name {
        display: none;
     }
     
}

@media only screen and (max-width : 480px) {

    .form-icons {
        height: 65px;
        -ms-grid-template-rows: 65px;
    }
    
    #reaction-form-message {
        height: 65px;
        margin-bottom: 20px;
        background-color: #ccc;
        text-align: center;
        color: white;
        margin-top: -65px;
        padding-top: 22px;
        border: 1px solid #cccccc;
        position: relative;
    }
    
    .form-icons {
        display: grid;
    }
    
    .reaction-name {
        margin-right: 0;
        margin-top: -3px;
    }
    
    .emotion-container {
        height: 7px;
        min-width: 1px;
        margin-top: -18px;
    }    
    
}


/* Block Quotes
-------------------------------------------------- */

.tncms-inline-relcontent-quote > h4,
blockquote footer {
    display: none;
}


/* Related content
-------------------------------------------------- */

.related-wrapper {
    border: 1px solid rgba(0,0,0,.08);
    padding: 20px 20px 30px 20px;
    border-top: 5px solid rgba(0,0,0,.08);
    margin-bottom: 20px;
    background-color: #f8f8f8;
}

.asset-fact.related-wrapper,
.asset-highlight.related-wrapper {
    border-top-color: #333;
}

.asset-highlight.related-wrapper {
    border-left: none;
    border-right: none;
    background-color: transparent;
}

.asset-update.related-wrapper,
.asset-correction.related-wrapper {
    border-left: none;
    border-right: none;
    border-bottom: none;
    background-color: transparent;
    padding-left: 0;
}

.asset-update.related-wrapper {
    border-top: none;
    border-bottom: 5px solid rgba(0,0,0,.08);
    padding-bottom: 20px;
}

.asset-update.related-wrapper h4 {
    font-weight: bold;
}

.asset-fact.related-wrapper {
    background-color: transparent;
}

.asset-quote blockquote span {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
}

.asset-quote blockquote {
    padding: 10px 20px;
    border-left: 15px solid #eee;
}

.related-wrapper .panel {
    border:none;
    box-shadow: none;
    margin-bottom: 0;
    background-color: transparent;
}

.related-wrapper .panel-heading {
    background-color:transparent;
    padding-left: 0;
    padding-bottom: 0;
    color: inherit;
}

.related-wrapper .panel-title {
    font-size: 18px;
}

.related-wrapper .panel-body {
    padding-left: 0;
    padding-bottom: 0;
}

.asset-correction.related-wrapper {
    padding-top: 10px;
}

#asset-below .asset-flash,
#asset-below .asset-swf,
#asset-below .asset-table {
    margin-bottom: 30px;
}

/* Breakthrough related content grid */
#asset-below .related-content.related-grid {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(1, minmax(auto, 1fr));
    margin-bottom: 20px;
}

@media (min-width: 768px) { 
    #asset-below .related-content.related-grid {
        grid-template-columns: repeat(4, minmax(auto, 1fr));
    }
    
    #asset-below .related-content.related-grid.related-boxes {
        grid-template-columns: repeat(3, minmax(auto, 1fr));
    }
}

/* Inline related content
-------------------------------------------------- */

.tncms-inline-relcontent {
    clear: both;
}

.tncms-inline-relcontent-quote {
    border: none;
}

.tncms-inline-relcontent-quote blockquote span {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
}

.tncms-inline-relcontent-quote blockquote {
    padding: 10px 20px;
    border-left: 15px solid #eee;
}

.tncms-inline-relcontent-correction {
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.tncms-inline-relcontent-fact,
.tncms-inline-relcontent-highlights{
    border-top-color: #333;
}

.tncms-inline-relcontent-update {
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.tncms-inline-relcontent-update h4 {
    font-weight: bold;
}

.tncms-inline-relcontent-bio,
.tncms-inline-relcontent-question,
.tncms-inline-relcontent-refer {
    background-color: #f8f8f8;
}

/* PDF inline asset */

.inline-asset.inline-pdf .pdf-content-alt {
    margin-bottom: 10px;
}

/* PDF display ad with business
-------------------------------------------------- */

.pdfdisplayad-default.related-business .pdf-content-alt {
    text-align: center;
}

.pdfdisplayad-default.related-business .pdf-content-alt img {
    margin: 0px auto;
    max-width: 50%;
}

.pdfdisplayad-default.related-business .pdf-content-alt .tnt-svg.tnt-download {
    font-size: 30px;
    margin-bottom: 10px;
}

.pdfdisplayad-default.related-business .asset-masthead h1 {
    margin: auto !important;
}

.pdfdisplayad-default.related-business .asset-masthead {
    margin: 20px auto 20px auto!important;
    text-align: center;
}

.pdfdisplayad-default.related-business .featuredon-biz-tile {
    text-align: center;
    margin: 0 auto 15px auto;
}

.pdfdisplayad-default.related-business ul.category-list {
    list-style-type: none;
    padding-left: 0;
}

.pdfdisplayad-default.related-business .pdf-cat-title,
.pdfdisplayad-default.related-business .pdf-title-time {
    font-size: 24px;
}


/* Time to read
-------------------------------------------------- */

.time-to-read-wrapper .read-time {
    padding-left: 5px;
}


/* Mediaspan purchase button
-------------------------------------------------- */

.mediaspan-purchase {
    margin-top: 40px;
}


/* Fixed article share for mobile viewports
-------------------------------------------------- */

#site-navbar-container.article-share .navbar .navbar-brand,
#site-navbar-container.article-share .site-search-full-mobile {
    opacity: 0;
    transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out;
}

#site-navbar-container .article-share-nav {
    opacity: 0;
    transition: opacity .2s ease-in-out, right .2s linear;
    -moz-transition: opacity .2s ease-in-out, right .2s linear;
    -webkit-transition: opacity .2s ease-in-out, right .2s linear;
    position: fixed;
    top:0;
    right:-768px;
    color:#fff;
    width: 100%;
    z-index: 999;
}

#site-navbar-container.article-share .article-share-nav {
    opacity: 1;
    right: 0;
    width: 100%;
    padding-left: 10px;
    z-index: 9999;
}

.article-share-nav > span {
    height: 50px;
    display: block;
    float: left;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    color: #fff;
    padding-right: 10px;
    text-transform: uppercase;
    width: 35%;
    font-size: small;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
    
.article-share-nav > .social-share-links {
    display: flex;
    height: 50px;
    margin: 0;
}
    
.article-share-nav > .social-share-links li {
    opacity: 1;
    justify-content: space-around;
    display: block;
    width: auto;
    flex-grow: 1;
}
    
.article-share-nav > .social-share-links li a {
    height:50px;
    color:#fff;
    text-align: center;
    margin-left:0 !important;
}

.article-share-nav > .social-share-links li:hover a {
    color:#fff;
}
    
.article-share-nav > .social-share-links li a .tnt-stack {
    height:50px;
}

.article-share-nav > .social-share-links li a {
    background-color: #000;
}

.article-share-nav > .social-share-links li a.fb {
    background-color: #3b5998;
}

.article-share-nav > .social-share-links li:hover a.fb {
    color: #d6d6d6;
}

.article-share-nav > .social-share-links li a.tw {
    background-color: #000;
}

@media screen and (min-width: 768px) {
    #site-navbar-container.article-share .navbar .navbar-brand,
    #site-navbar-container.article-share .site-search-full-mobile {
        opacity: 1;
    }
}

/* Cropping tools
-------------------------------------------------- */

.cropped-image-container,
.crop-btns-container {
    margin-top: 15px;
}

.cropped-image-container .image-upload,
.cropped-image-container .image-preview {
    max-width:100%;
}

/* Fixed contant buttons for realtor
-------------------------------------------------- */

.agent-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    padding: 20px;
    z-index: 999;
    box-shadow: 0px -3px 6px -1px rgba(0,0,0,0.2);
}

#site-footer-container.agent-contact {
    padding-bottom: 75px;
}

@media only screen and (max-width : 374px) {
    .agent-container .hide-agent {
        display: none;
    }
}

/* External syndicated links
-------------------------------------------------- */

a.tnt-inline-link {
    float: left;
    margin: 5px 5px 0 0;
}

p.tncms-inline-link a {
    font-size: 14px;
    margin-bottom: 0;

}

/* Letterbox styles
-------------------------------------------------- */

.letterbox-style-blur .tnt-blurred-image,
.tnt-video-container.audio-player .tnt-blurred-image {
    width: 100%;
    position: absolute;
    left: 0;
    margin-left: 0;
    margin-top: 0;
    z-index: 0;
    height: 100%;
    top: 0;
    overflow: hidden;
}

.letterbox-style-blur .tnt-blurred-image img,
.tnt-video-container.audio-player .tnt-blurred-image {
    filter: blur(40px);
    opacity: 0.6;
    height: 100%;
    width: 100% !important;
    max-height: 100% !important;
    object-fit: inherit !important;
}

.tnt-video-container.audio-player .tnt-blurred-image {
    background-size: cover;
}

.letterbox-style-blur img.blur,
.letterbox-style-blur .owl-item img.blur,
.letterbox-style-blur .caption-container,
.letterbox-style-blur .image,
.letterbox-style-blur .carousel-inner .photo-container,
.letterbox-style-blur .tnt-slider .photo-container a > img,
.card.feature.letterbox-style-blur .centered-content {
    z-index: 9;
    position: relative;
}

.modal .letterbox-style-blur .image img,
.modal .letterbox-style-blur .owl-item img.blur{
    z-index: inherit;
}

.modal .letterbox-style-blur .tnt-blurred-image {
    display: none;
}

.photo-carousel.letterbox-style-blur .item {
    text-align: center;
    overflow: hidden;
}

.letterbox-style-default.photo.layout-vertical .image {
    background-color: rgba(0,0,0,.85);
}

.letterbox-style-white.photo.layout-vertical .image,
.card .card-image .photo.layout-vertical.letterbox-style-white, 
.photo-carousel.letterbox-style-white .item .photo-container,
.asset-photo .letterbox-style-white .image {
    background-color: #fff;   
}

.modal-image-container .letterbox-style-white.photo.layout-vertical .image {
    background-color: inherit;   
}

.card.feature.letterbox-style-blur .image .asset-icon,
.card figure.letterbox-style-blur .image .asset-icon{
    z-index: 9999;
}

.photo-carousel.letterbox-style-blur .item .photo-container {
    position: relative;
}

.hydrated .photo.layout-vertical img.letterbox {
    width: auto;
    margin: 0 auto;
    z-index: 10;
    position: relative;
}

.hydrated .related-sidebar .letterbox-style-blur .image .tnt-blurred-image img{
    position: inherit;
}

.letterbox-style-blur .tn-img-layout-fixed-height,
.letterbox-style-blur .tn-img-layout-responsive {
    z-index: 100;
}

.hydrated .asset-photo .letterbox-style-blur img,
.photo.letterbox-style-blur img.full,
.card .card-image .photo.letterbox-style-blur img,
.photo.layout-vertical img.letterbox.blur {
    position: relative;
    z-index: 999;
}

.hydrated .card .card-image .photo.letterbox-style-blur tn-img img {
    position: absolute;
}

/* Navigation focus for tabindexing
-------------------------------------------------- */

#site-navbar-container a:focus,
.navbar-toggle:focus,
.navbar-default .navbar-nav>li>button.nav-link:focus {
    outline: thin dotted;
}

/* OpenWeb Widget
--------------------------------------------------*/
#spot-im-conversation-header h2 {
    display: none;
}
.header__header___3dUzU {
    display: none;
}
.spcv_conversation-header {
    display: none;
}

/* Keyword Search
-------------------------------------------------- */

.faceted-keyword-search-btn {
    border: 1px solid #ccc;
    border-left: 0px;
}
.faceted-keyword-search-btn button {
    background-color: transparent;
    padding: 5px 5px;
}
#classifieds-keyword-search-form .form-group > div {
    display: flex;
}

/*  Rayos blockquote display fix
-------------------------------------------------- */

blockquote {
    border-left: solid 1px #ccc;
    padding-left: 20px;
    margin-left: 20px;
    margin-right: 40px;
}

/*  Type of work
-------------------------------------------------- */

.type-of-work {
    display: inline-block;
    height: 20px;
    width: auto;
    clear: right;
    margin-bottom: 15px;
    position: relative;
}

.type-of-work.has-type  {
    margin-left: 7px;
}

.type-of-work .popover.bottom.show {
    margin: 35px auto;
    width: 200px;
}

.type-of-work .popover.bottom.show >.arrow {
    left: 15%;
}

.type-of-work .type:hover {
    cursor: pointer;
}

.type-text {
    padding-top: 5px;
}

.type-of-work button.close {
    left: -5px;
    top: -2px;
    position: relative;
}

.type-of-work .popover-content {
    padding: 15px 14px 10px 14px;
}

.type-of-work .tnt-svg.tnt-stack-1x {
    height: 15px;
    width: 15px;
}

.type-of-work.has-type .separator {
    font-size: 20px;
    margin-top: -4px;
    position: absolute;
    display: inline-block;
}

.type-of-work.has-type .the-type {
    margin-left: 10px;
}

.type-of-work .the-type {
    font-size: 13px;
    position: inherit;
    margin-top: 3px;
}

.has-type .asset-header .asset-label-flags {
    float: left; 
}

.type-of-work .tnt-info-circle{
    margin: 4px 5px 0 0;
}

.has-work {
    width: auto;
    float: left;
}

.has-cover-art .type-of-work p{
    color: #000
}

.has-cover-art .tow-wrapper.no-flags{
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

.has-cover-art .tow-wrapper.no-flags .type-of-work .the-type {
    position: relative;
}

.has-cover-art .type-of-work {
    text-align: start;
}

.has-cover-art .has-work {
    text-align: end;
}

.article-longform .has-flags .has-work {
    float: none;
    display: inline-block;
}

.article-longform .type-of-work .the-type {
    position: relative;
}

.tow-wrapper {
    display: inline-block;
}

/*  Updated presentation modes
-------------------------------------------------- */

.tnt-inline-presentation-hide-details .card-body,
.tnt-inline-presentation-hide-details figure .caption,
.asset .tnt-inline-asset.tnt-inline-presentation-hide-details figure .caption,
.tnt-inline-presentation-hide-details .asset-icon{
    display: none;
}

.inline-asset.tnt-inline-presentation-hide-details .card {
    border: none;
}

.tnt-inline-width-one-third,
.tncms-inline-width-one-third,
.tncms-inline-relcontent.tncms-inline-alignment-left.tncms-inline-width-one-third,
.tncms-inline-relcontent.tncms-inline-alignment-right.tncms-inline-width-one-third,
.tncms-inline-relcontent.tncms-inline-alignment-center.tncms-inline-width-one-third{
    width: 33%;
}
        
.tnt-inline-width-two-thirds,
.tncms-inline-width-two-thirds,
.tncms-inline-relcontent.tncms-inline-alignment-left.tncms-inline-width-two-thirds,
.tncms-inline-relcontent.tncms-inline-alignment-right.tncms-inline-width-two-thirds,
.tncms-inline-relcontent.tncms-inline-alignment-center.tncms-inline-width-two-thirds{
    width: 66%;
}

@media (max-width: 767px) {
    .tnt-inline-width-two-thirds,
    .tncms-inline-width-two-thirds,
    .tnt-inline-image.tnt-inline-alignment-right.tnt-inline-width-two-thirds, 
    .tnt-inline-image.layout-vertical.tnt-inline-alignment-default.tnt-inline-width-two-thirds, 
    .tnt-inline-image.tnt-asset-presentation-mugshot.tnt-inline-alignment-default.tnt-inline-width-two-thirds,
    .tnt-inline-image.tnt-inline-alignment-center.tnt-inline-width-two-thirds, 
    .tnt-inline-image.tnt-inline-width-half.tnt-inline-alignment-default.tnt-inline-width-two-thirds,
    .tnt-inline-image.tnt-inline-alignment-left.tnt-inline-width-two-thirds,
    .tnt-inline-alignment-left.tnt-inline-youtube,
    .tnt-inline-alignment-center.tnt-inline-youtube,
    .tnt-inline-alignment-right.tnt-inline-youtube,
    .tnt-inline-alignment-default.tnt-inline-youtube,
    .tnt-inline-alignment-left.tnt-inline-video,
    .tnt-inline-alignment-center.tnt-inline-video,
    .tnt-inline-alignment-right.tnt-inline-videoe,
    .tnt-inline-alignment-default.tnt-inline-video,
    .tncms-inline-relcontent.tncms-inline-alignment-left.tncms-inline-width-two-thirds,
    .tncms-inline-relcontent.tncms-inline-alignment-right.tncms-inline-width-two-thirds,
    .tncms-inline-relcontent.tncms-inline-alignment-center.tncms-inline-width-two-thirds,
    .tnt-inline-width-one-third,
    .tncms-inline-width-one-third,
    .tnt-inline-image.tnt-inline-alignment-right.tnt-inline-width-one-third, 
    .tnt-inline-image.layout-vertical.tnt-inline-alignment-default.tnt-inline-width-one-third, 
    .tnt-inline-image.tnt-asset-presentation-mugshot.tnt-inline-alignment-default.tnt-inline-width-one-third,
    .tnt-inline-image.tnt-inline-alignment-center.tnt-inline-width-one-third, 
    .tnt-inline-image.tnt-inline-width-half.tnt-inline-alignment-default.tnt-inline-width-one-third,
    .tncms-inline-relcontent.tncms-inline-alignment-left.tncms-inline-width-one-third,
    .tncms-inline-relcontent.tncms-inline-alignment-right.tncms-inline-width-one-third,
    .tncms-inline-relcontent.tncms-inline-alignment-center.tncms-inline-width-one-third,
    .tnt-inline-image.tnt-inline-width-one-third{
        width: auto;
        float: none;
        clear: both;
        margin-right: 0;
        margin-left: 0;
    }
    .tnt-inline-image.tnt-inline-width-one-third{
        width: 50%;
    }
}

/* Dynamic search grid 
-------------------------------------------------- */
.tn-search-grid #asset-content p.tnt-summary {
    margin: 0;
}

.tn-search-grid figcaption {
    display: none;
}

.tn-search-grid img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.tn-search-grid .search-grid { 
  margin-right: auto;
  margin-left: auto;
}

.tn-search-grid .tnt-dynamic-collection {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.tnt-dynamic-collection .page .content .asset {	
    padding-bottom: 0px;	
    display: inline-block;	
    width: 100%;	
    max-height: 100%;	
    position: relative;	
}

.tn-search-grid .content {
  columns: 1;
  column-gap: 20px;
}

.tn-search-grid .asset{
  margin-bottom: 2px;
}

.tn-search-grid .asset.has-image,
.tn-search-grid .content{
    vertical-align: top;
    margin-bottom: 10px;
    padding-bottom: 0;
}

.tn-search-grid .page {
    position: relative;
    clear: both;
}

.tn-search-grid .search-grid .asset, 
.tncms-inline-asset {
  overflow: hidden;
}

.tn-search-grid .tncms-inline-asset {
  overflow: hidden;
}

.tn-search-grid .modal.fullscreen.search-collection .photo-carousel {
    width: 900px;
    margin: 0 auto;
}

.tn-search-grid .card .card-image .photo.layout-vertical img {
    max-height: inherit;
    width: 100%;
    margin: 0 auto;
}

.tn-search-grid .tnt-load-more {
    margin: 0 auto 20px;
}

.tn-search-grid .tnt-dynamic-collection {
	margin-bottom: 43%;
}

.tn-search-grid .photo-carousel-control-container {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100px;
    bottom: 0;
    right: 0;
    top: calc(50% - 60px);
    z-index: 999;
}

.tn-search-grid .modal.fullscreen.search-collection .photo-carousel .photo-carousel-control.left {
    left: 0;
    width: 40px;
}

.tn-search-grid .modal.fullscreen.search-collection .photo-carousel .photo-carousel-control.right {
    right: 15px;
    width: 40px;
}

@media only screen and (min-width: 768px) {
	.tn-search-grid .tnt-dynamic-collection .tnt-ads-container {
	    width: 40vw;
	    position: absolute;
	    margin-left: -22vw;
	    left: 55%;
	    bottom: 0;
	    column-count: 1;
	    clear: both;
	    height: 290px;
	    display: block;
	    margin-bottom: 20px;
	    top: 100%;
	}
}

.tn-search-grid .tnt-ads-container iframe, 
.tn-search-grid .tncms-region-ads iframe{
    border: 0px;
    vertical-align: bottom;
    margin-top: 20px;
}

.tn-search-grid .tnt-dynamic-collection > div:not(:first-child) {
    margin-top: 340px;
}

.tn-search-grid .overlay {
    display: inline-block;
    background-color: rgba(255, 255, 255, .0);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    color: #333;
    transition: all 300ms ease-out;
}

.tn-search-grid .overlay:hover {
    display: inline-block;
    background-color: rgba(255, 255, 255, .5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    color: #333;
    transition: all 300ms ease-out;
}

.tn-search-grid .overlay:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}

.tn-search-grid .overlay .tnt-svg {
    color: rgba(0, 0, 0, .6);
    font-size: 50px;
    margin: auto;
    text-align: center;
    vertical-align: middle;
}

.tn-search-grid .overlay .title {
    font-size: 11px;
    line-height: 1.2em;
    padding: 5px;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    text-align: left;
    top: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tn-search-grid .tncms-inline-asset:hover .overlay {
    opacity: 1;
}

.tn-search-grid .asset {
    width: 100%;
    text-align: center;
}

.tn-search-grid .card {
    margin: 0;
}

.tn-search-grid .has-image .card-container{
    background-color: transparent;
    padding: 0;
}

.tn-search-grid .card-container {
    background-color: #efefef;
    padding: 20px;
}

.tn-search-grid .tnt-headline a,
.tn-search-grid .tnt-headline {
    font-size: 18px;
}

.tn-search-grid .card.has-image > .card-container > .card-body {
    padding: 0;
    margin-left: 0;
}

.tn-search-grid .card .card-headline h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 0;
}

.tn-search-grid .card-social,
.tn-search-grid .actions.pull-right{
    display: none;
}

.tn-search-grid .search-collection .actions.pull-right {
    display: inline-block;
}

.tn-search-grid .inline-summary .actions.pull-right {
    position: absolute;
    bottom: 10px;
    display: inline-block;
    z-index: 99;
    right: 0;
    opacity: 0;
    z-index: 99999;
}

.tn-search-grid .dynamic .has-image .tnt-headline a,
.tn-search-grid .static .has-image .tnt-headline{
    font-size: 14px;
    bottom: 24px;
    position: absolute;
    padding-left: 5px;
    width: 65%;
    z-index: 999;
    text-align: left;
    color: #fff;
    left: 0;
    opacity: 0;
    background-color: rgba(000, 000, 000, .7);
    width: 100%;
    height: 100%;
    top:0;
    display: flex;
    align-items: end;
    padding: 0;
}

.tn-search-grid .static .has-image .tnt-headline a{
    font-size: 14px;
    color: #fff;
}

.tn-search-grid .dynamic .has-image .card-headline a:hover,
.tn-search-grid .static .has-image .card-headline .tnt-headline:hover,
.tn-search-grid .static .has-image .card-headline .tnt-headline a:hover{
    text-decoration: none;
    opacity: 1;
    width: 100%;
    height: 100%;
    bottom: 10px;
    display: flex;
    align-items: end;
    padding: 10px;
    cursor: pointer;
}

.tn-search-grid .card-lead,
.tn-search-grid div.caption-container > div > div.actions.pull-right.hidden-print > a{
    opacity: 0;
    height: 0;
}

.tn-search-grid .followed-notify-link,
.tn-search-grid .collection-gallery-content-container{
    margin-top: 20px;
}

.tn-search-grid .inline-grid .actions.pull-right.hidden-print {
    display: inline-block;
    position: absolute;
    z-index: 9999999;
    bottom: 10px;
    right: 0;
    opacity: 0;
}

.tn-search-grid .caption-text p{
    padding: 10px 0;
}

.tn-search-grid .page .tnt-spinner{
    top: 50%;
    position: absolute;
    color: #fff;
    font-size: 24px;
    z-index: 1;
    display: inline-block;
}

.tn-search-grid .search-grid.static .tnt-headline {
    font-size: 14px;
    margin-bottom: inherit;
}

.tn-search-grid .photo-carousel .caption-container {
    z-index: 999;
}

.tn-search-grid .photo-carousel .item .photo-container img {
    display: table;
    z-index: 99;
    table-layout: fixed;
}

.search-grid.dynamic .card .card-image .photo.letterbox-style-blur img, 
.search-grid.dynamic .hydrated .asset-photo .letterbox-style-blur img, 
.search-grid.dynamic .photo.layout-vertical img.letterbox.blur, 
.search-grid.dynamic .photo.letterbox-style-blur img.full {
    z-index: 99;
}

.tn-search-grid .modal.fullscreen.search-collection.in {
    z-index: 99999;
}
    
@media only screen and (max-width: 1023px) and (min-width: 768px) {  
    .tn-search-grid .content {
        columns: 2;
      }
}

@media only screen and (max-width: 768px) {  
    .tn-search-grid .modal.fullscreen.search-collection .photo-carousel {
        width: inherit;
    }
}

@media only screen and (min-width: 1024px) {
    .tn-search-grid .dynamic .content {
        columns: 3;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(235px,1fr));
        grid-auto-rows: 0;
        grid-template-rows: max-content;    
        row-gap: 12px;
    }
    
    .tn-search-grid .content {
        columns: 3;
    }
  
    .tn-search-grid .tnt-dynamic-collection .tnt-ads-container {
        width: 40vw;
        margin-left: -23vw;
        height: 290px;
        top: 96%;
    }
}

@media only screen and (max-width: 1024px) {
    .tn-search-grid .dynamic .content {
        columns: 2;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, minmax(235px,1fr));
        grid-auto-rows: 0;
        grid-template-rows: inherit;    
        row-gap: 12px;
    }
}
    
@media only screen and (min-width: 768px) {    
    .tn-search-grid .tnt-dynamic-collection .tnt-ads-container {
        margin-left: -25vw;
    }
    
}

@media only screen and (max-width: 426px) {    
    .tn-search-grid .tnt-dynamic-collection {
    	margin-bottom: 81%;
    }
    .tn-search-grid .dynamic .content {
        display: grid;
        columns: 1;
        grid-template-columns: repeat(1, minmax(235px,1fr));
    }
}

@media only screen and (max-width: 376px) {    
    .tn-search-grid .tnt-dynamic-collection {
    	margin-bottom: 93%;
    }
}

@media only screen and (max-width: 321px) {    
    .tn-search-grid .tnt-dynamic-collection {
    	margin-bottom: 110%;
    }
}

@media only screen and (min-width: 1025px) {
    .tn-search-grid .tnt-dynamic-collection .tnt-ads-container {
        position: absolute;
        margin-left: -22vw;
        height: 290px;
        top: 100%;
    }
}

@media print{    
    .tn-search-grid .content {
        columns: 3;
    }
    
    .tn-search-grid .page {
        display: inline-block;
        position: relative;
        clear: both;
    }
    
    .tn-search-grid .tnt-dynamic-collection {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
    }
    
    .tn-search-grid .search-grid {
        max-width: fit-content;
        margin-right: auto;
        margin-left: auto;
    }
}

.tn-search-grid .search-grid.dynamic .asset.has-image,	
.tn-search-grid #asset-content figure .image, 	
.tn-search-grid .asset-photo .image, 	
.tn-search-grid .inline-image .image {	
    margin-bottom: 0px;	
}	
.tn-search-grid.static .asset {	
    margin-bottom: 15px;	
}


/* Dynamic vertical scroll
-------------------------------------------------- */
.vertical-dynamic .asset {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.vertical-dynamic .tnt-load-more{
    margin: 40px auto;
}

.type-collection.subtype-dynamic button#follow-collection {
    margin-top: 20px;
}

.vertical-dynamic .main-content .tnt-asset-type-article.card.showcase,
.vertical-dynamic .main-content .hidden-details .asset span,
.vertical-dynamic .main-content .hidden-details .asset p,
.vertical-dynamic .main-content .hidden-details .asset .card-meta,
.vertical-dynamic .main-content .hidden-details .asset caption {
    display: none;
}

.vertical-dynamic .main-content .hidden-details .tnt-asset-type-article.card.showcase,
.vertical-dynamic .main-content .hidden-details .tnt-asset-type-article.card.showcase p {
    display: block;
}

.vertical-dynamic .main-content video {
    width: 100%;
}

/* Static search grid
-------------------------------------------------- */
.tn-search-grid.static .photo-carousel-control-static {
    font-size: 18px;
    padding: 5px 10px;
    border: 1px solid rgba(0,0,0,.1);
    margin-bottom: 10px;
    color: rgba(0,0,0,.4)
}

.tn-search-grid.static .photo-carousel-control-container-paging {
    margin-bottom: 20px;
    float: right;
    position: relative;
    z-index: 99;
}

.tn-search-grid.static .paging-count {
    float: right;
    font-size: 14px;
    font-weight: bold;
    padding-right: 10px;
    position: relative;
    top: 7px;
}

.tn-search-grid.static .photo-carousel-control-static:hover {
    cursor: pointer;
    color: rgba(0,0,0,.8);
    background-color: rgba(0,0,0,.05);
}

.tn-search-grid.static .photo-carousel-control-static.disabled:hover{
    cursor: not-allowed;
}

.tn-search-grid.static .card .card-image .photo.layout-vertical img {
	z-index: 99;	
}

.tn-search-grid .asset{
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Weather
-------------------------------------------------- */
.tnt-weather-radar {
    position: relative;
    background: #efefef;
    margin-bottom: 20px; 
    height: 600px;
    width: 100%;
}

.tnt-weather-radar.radar-sm {
    height: 300px;
}

.tnt-weather-radar iframe {
    position: relative;
}


/* Browser Compatibility Alert
-------------------------------------------------- */
#browser-compatibility-modal .modal {
    top: unset;
  }
  
  #browser-compatibility-modal .modal.in .modal-dialog {
    margin: unset;
    width: 100%;
  }
  
  #browser-compatibility-modal .modal-content {
    border-radius: unset;
  }
  
  #browser-compatibility-modal .modal-header {
    background: #000;
    color: #fff;
  }
  
  #browser-compatibility-modal .modal-header .close {
    color: #fff;
  }
  
  #browser-compatibility-modal .modal-content .btn-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
    justify-content: center; /* horizontally centers the grid columns */
    align-items: center; /* vertically centers the grid items */
  }
  
  @media (max-width: 768px) {
      #browser-compatibility-modal .modal-content .btn-group {
        grid-template-columns: 1fr;
      }
    }
  
  #browser-compatibility-modal .modal-content .btn-group a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }
  
  #browser-compatibility-modal .modal-content .btn-group svg {
    width: 2em;
  }

/* Star Ratings
-------------------------------------------------- */
.ratings-wrapper {
    display: inline-block;
    font-size: 4rem;
}
.ratings-wrapper:not(:checked) > input {
    position: absolute;
    opacity: 0;
}
.ratings-wrapper:not(:checked) > label {
    float: right;
    cursor:pointer;
}
.ratings-wrapper > input:checked ~ label {
    color: #0099FF;    
}
.ratings-wrapper:not(:checked) > label:hover,
.ratings-wrapper:not(:checked) > label:hover ~ label {
    color: #0099FF;  
}
.ratings-wrapper > input:checked + label:hover,
.ratings-wrapper > input:checked + label:hover ~ label,
.ratings-wrapper > input:checked ~ label:hover,
.ratings-wrapper > input:checked ~ label:hover ~ label,
.ratings-wrapper > label:hover ~ input:checked ~ label {
    color: #004D80;
}
.star-ratings-info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap:0.5rem;
    align-items:center;
}

.star-ratings {
    font-size: 3rem;
}

.star-info {
    font-size: 1.5rem;
}

.star-ratings-fill {
    color: #09f;
    display:flex;
    overflow:hidden;
    position:absolute;
}
.empty-star-ratings {
    position:relative;
    display:flex;
    overflow:hidden;
    flex-direction:row-reverse;
    float:right;
}

#star-ratings-wrap {
    position: relative;
}

#star-ratings-form-message {
    background-color: #ccc;
    text-align: center;
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
}

#star-ratings-form-message p {
    font-weight: bold;
    margin: auto;
    padding: 1.5rem .5rem;
}